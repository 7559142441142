// Custom styles here
// Note, if this file exceeds 200 lines of code, it must be broken up into multiple files and imported using @import

/** @define wrapper */
.wrapper {
  overflow: hidden;
  position: relative;

  @include media('<tablet') {
    position: static;
  }
}

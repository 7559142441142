/** @define fav-aspect-sec */
.fav-aspect-sec {
  background: $brown;
  position: relative;
  z-index: 999;

  @include media('<desktop') {
    margin-top: 20px;
    padding: 40px 0 0;
  }

  &__image {
    margin-right: -100px;
    position: relative;

    @include media('<desktop') {
      margin-right: 0;
    }

    &--img {

      @include media('<widescreen') {
        width: 100%;
      }

      @include media('<desktop') {
        display: block;
        margin: 20px auto 0;
        max-width: 100%;
        width: auto;  
      }
    }
  }
}

/** @define team2-sec */
.team2-sec {
  position: relative;
}

/** @define recipe-sec */
.recipe-sec {
  background: url('../img/bg-image-sec.jpg') #535558;
  background-size: cover;
  position: relative;
}



/* stylelint-disable */
.chef-page {
  .positioning-sec {
    padding-bottom: 86px;

    @include media('<extrawidescreen') {
      padding-bottom: 0;
    }
  }
  .positioning-sec__model {
    left: 66px;
    top: -180px;
    z-index: 999;
    @include media('<extrawidescreen') {
      width: 500px;
      left: -166px;
      top: -140px;
    }
  }
}
.chef-svg {
  left: auto;
  bottom: auto;
   img {
    width: auto;
    float: right;
   }
}
.team2-sec {
  .dispflex {
    @include media('<desktop') {
      display: block;
      padding-top: 0;
      padding-bottom: 20px;
      .image2 {
        border-bottom: 1px solid $brown;
        position: relative;
        margin: 0 -15px;
        img {
          width: 100%;
          display: block;
          height: auto;
        }
      }
    }
  }
}
.fav-aspect-sec {
  .dispflex {
    @include media('<desktop') {
      display: block;
      padding-bottom: 30px;
    }
  }
  .sec-data {
    color: $white;
    width: 100%;
    display: table;
    .sec-data__heading {
      color: $white;
      @include media('<widescreen') {
        @include font-size(36px, 40px);
        margin-bottom: 20px;
      }
    }
    .btn-default {
      border-color: $white;
      color: $white;

      &:hover {
        background: $white;
        color: $brown !important;
      }
    }
    .sec-data__text {
      color: $white;
    }
    .sec-data__heading {
      margin-left: -18px;
      margin-right: -18px;

      @include media('<widescreen') {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  @include media('<extrawidescreen') { 
    .col-sm-pull-1 { right: 0;}
  }
  
}
.recipe-sec {
  .sec-data {
    color: $white;
  }
  .image2 {
    position: relative;
    margin-left: -75px;
    z-index: 99;
    img {
      display: block;
    }
    @include media('<desktop') {
      border-bottom: 1px solid $brown;
      position: relative;
      margin: 0 -15px;
      img { 
        width: 100%;
        margin-left: 0;
      }
    }
    
  }
  .align-center {
    padding-left: 15% !important;
    @include media('<desktop') {
      padding-left: 0 !important;
    }
  }
  .dispflex {
    @include media('<desktop') {
      display: block;
      padding-top: 0;
      padding-bottom: 20px;
    }
  }
}
.align-content {
  display: table;
  width: 100%;
  height: 100%;
  .align-center {
    display: table-cell;
    vertical-align: middle;
    padding: 10px 0;
  }
}
.btn-default {
  &.brown {
    border: 1px solid $white;
    color: $white;
    &:hover {
      background: $white;
      color: $brown !important;
    }
  }
}
.fullblock-image {
  position: relative;
  margin-bottom: 76px;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
  @include media('<desktop') {
    margin-bottom: 10px;
  }
}

.page-template-chef {
  .team2-sec {
    .image2 {
      img {
        width: auto;
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
}


/** @define events-section */
.events-section {
  padding: 70px 0 0;
  position: relative;

  @include media('<desktop') {
    padding: 30px 0 0;
  }

  &__holder {
    font-family: 'proxima-nova',sans-serif;
    position: relative;

    &--heading {
      @include font-size(79px, 83px);
      color: $brown;
      font-family: 'tablet-gothic-compressed',sans-serif;
      font-weight: 200;
      letter-spacing: 4px;
      margin: 0;
      position: relative;
      text-align: center;

      @include media('<desktop') {
        @include font-size(40px, 43px);
      }

      @include media('<tablet') {
        @include font-size(30px, 34px);
        text-align: center;
      }
    }

    &--text {
      @include font-size(14px, 30px);
      color: $gray3;
      letter-spacing: 0.7px;
      margin-top: 37px;
      padding: 0 6.5%;
      text-align: center;

      @include media('<desktop') {
        padding: 0;
      }

      @include media('<tablet') {
        margin-top: 15px;
      }
    }
  }
}
/* stylelint-disable */
.carousel-inner {
  overflow: visible;
}
.events-section__holder--heading {
  span {
    display: inline-block;
    vertical-align: top;
    padding: 0 35px;
    position: relative;
    z-index: 9;

    @include media('<tablet') {
      padding: 0 10px;
    }
      
      &::before {
        position: absolute;
        left: 0;
        padding-left: 9999px;
        margin-left: -9999px; 
        content: '';
        background: $brown;
        height: 1px;
        top: 57%; 
      }

      &::after {
        position: absolute;
        right: 0;
        padding-right: 9999px;
        margin-right: -9999px;
        content: '';
        background: $brown;
        height: 1px;
        top: 57%; 
      }
    }
}

/** @define galleryslider */
.galleryslider {
  margin: 90px 0 0;
  position: relative;
  width: 100%;
  z-index: 99;

  @include media('<desktop') {
    margin: 30px 0 0;
  }

  &__img {
    display: block;
    width: 100%;
  }
}

/** @define carousel-nav */

.carousel-nav {
  background: #fff;
  bottom: -56px;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.2);
  display: table;
  height: 112px;
  left: -6px;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 232px;
  z-index: 999;

  @include media('<desktop') {
    bottom: -25px;
    height: 61px;
    width: 116px;
  }

  @include media('<tablet') {
    bottom: -12px;
    height: 30px;
    width: 58px;
  }

  &__prev {
    background: #fff;
    color: $brown;
    display: block;
    height: 100%;
    position: absolute;
    left: 0;
    text-align: center;
    top: 0;
    width: 115px;

    @include media('<desktop') {
      width: 58px;
    }

    @include media('<tablet') {
      width: 26px;
    }
    
    &:focus {
      color: $brown; 
      text-decoration: none;
    }

    &:hover {
      background: $brown;
      color: #fff;
      text-decoration: none;

      &--icon { color: #fff; }
    }

    &--icon {
      display: block;
      font-size: 58px;
      font-style: normal;
      padding-top: 23px;

      @include media('<desktop') {
        font-size: 26px;
        padding-top: 13px;
      }

      @include media('<tablet') {
        font-size: 18px;
        padding-top: 5px;
      }
    }
  }

  &__next {
    color: $brown;
    display: block;
    height: 100%;
    right: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 115px;

    @include media('<desktop') {
      width: 58px;
    }
    
    @include media('<tablet') {
      width: 26px;
    }

    &:focus {
      color: $brown; 
      text-decoration: none;
    }
    
    &:hover {
      background: $brown;
      color: $white; 
      text-decoration: none;
      
    }

    &--icon { 
      display: block;
      font-size: 58px;
      font-style: normal;
      padding-top: 23px;

      @include media('<desktop') {
        font-size: 26px;
        padding-top: 13px;
      }

      @include media('<tablet') {
        font-size: 18px;
        padding-top: 5px;
      }

    }
  }  
}

/** @define featured-box */
.featured-box {
  margin-bottom: 90px;
  position: relative;

  @include media('<desktop') {
    margin-bottom: 30px;
  }

  &__image {
    position: relative;
    z-index: 9;
  }

  &__description {
    background: $white;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    height: 400px;
    margin-left: -125px;
    padding: 0 43px 0 53px;
    position: relative;
    width: 589px;
    z-index: 999;

    @include media('<tablet') {
      height: auto;
      padding: 25px 20px;
    }

    &--heading {
      @include font-size(50px, 53px);
      color: $brown;
      font-family: 'tablet-gothic-compressed',sans-serif;
      font-weight: 200;
      margin: 0;
      text-transform: uppercase; 
    }

    &--text {
      @include font-size(18px, 29px);
      color: $gray3;
      margin-top: 18px;

      @include media('<tablet') {
        @include font-size(14px, 20px);
        margin-top: 10px;
      }
    }
  }
}


/** @define pricingbox */
.pricingbox {
  margin-bottom: 50px;

  &__title {
    @include font-size(40px, 42px);
    color: $brown;
    font-family: 'tablet-gothic-compressed',sans-serif;
    font-weight: 200;
    margin: 5px 0 0;
    padding: 0;
    
    @include media('<tablet') {
      @include font-size(30px, 33px);
    }

  }

  &__detail {
    @include font-size(14px, 30px);
    color: $gray3;
    font-family: 'proxima-nova';
    font-weight: 400;
    margin: 0;
  }
}




/* stylelint-disable */
.page-template-pricing {
  .openpos {
    margin-bottom: 50px;
  }
}
.featured-box {
  &.right-dir {
    .featured-box__description {
      margin-left: 0;
      margin-right: -125px;
    }
  }
  .btn-default {
    float: left;
  }
}

.celeb-sec-image {
  &.ev-coor {
    background: url('../img/ev-coor-img2.jpg') no-repeat;
    background-size: cover;

    @include media('<desktop') {
    background: #fbf8f4; 
    }
  }
  &.fath {
    position: relative;
    z-index: 999;
    background: url('../img/father-img2.jpg') no-repeat;
    background-size: cover;
    
    @include media('<tablet') {
      background: url('../img/bg-image-sec.jpg') no-repeat !important;
      background-size: cover !important;
    }
    .sec-data__text {
      color: $white;
    }
    .venues { background: none;}    
  }
  &.groom-plan {
    background: url('../img/groom2.jpg') no-repeat;
    background-size: cover;
    position: relative;
    z-index: 99;
    .sec-data { color: $white;}
  }
}

.logistics-sec {
  &.ev-coor {
    background-image: url('../img/ev-coor-img4.jpg');
  }
}

.no-margin { margin: 0 !important;}
.event-manager {
  position: relative;
  .positioning-sec .sec-data__heading {
    &.text-left {
      span { 
        margin-left: 79px;
        @include media('<desktop') {
          margin-left: 0;
        }
      }
    }
  }
  .positioning-sec__model {
    top: -20px;
    left: 15%;
    
    @include media('<extrawidescreen') {
      top: 0;
      left: 5%;
      width: 500px;
    }

  }
  .celeb-sec-image {
    &.ev-coor {
      background: url('../img/ev-manager-img2.jpg') no-repeat;
      background-size: cover;

      @include media('<desktop') {
      background: #fbf8f4; 
      }
    }
  }
  .logistics-sec {
    &.ev-coor {
      background-image: url('../img/ev-manager-img4.jpg');
    }
  }
}
@include media('<tablet') {
  .quote-carousel { padding-bottom: 20px}
  .full-page-img { display: none;}
}
.imagine {
  .two-columns {
    padding: 116px 0 82px;
  }
}
@include media('<desktop') {
  .featured-box__image {
    img {
      width: 100%;
      display: block;
      margin-bottom: 20px;
      height: auto;
    }
  }
  .featured-box.right-dir .featured-box__description {
    margin-right: 0;
  }
  .featured-box .featured-box__description {
    margin-left: 0;
    width: 100%;
    padding: 15px 25px;
  }
  .featured-box .btn-default {
    margin:0 0 25px; 
  }
  .celeb-sec-image__image3 {
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}

.father {
  .positioning-sec2 {
    padding-bottom: 80px;
    @include media('<tablet') {
      padding-bottom: 30px;
    }
  }
  .venues::after { display: none;}
  .full-page-img {
    img {
     width: auto !important;
    }
  }
}
.full-page-img {
  position: relative;

  .container-fluid {
    position: relative;
    img {
      position: absolute;
      left: 0;
      top: 20px;
    }
  }
}
.container-fluid {
  &.full-page-img {
    position: relative;
     img {
      position: absolute;
      left: 0;
      top: -20px;
     }
  }
}

.groom {
  .positioning-sec__model {
    left: 0;
    top: 0;
  }
  .text-left { 
    span {margin-left: 0 !important;}
  }
  .celeb-sec {
    padding: 98px 0 119px;
    @include media('<desktop') {
      padding: 40px 0 30px;
    }
  }
  .image-venues {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    @include media('<desktop') {
      display: none;
    }
  }
  .venues::after { display: none;}
}
.sec-data__heading .border-line:before,
.sec-data__heading .border-line:after { z-index: -1;}
.celeb-sec2 {
  background: url('../img/groom5.jpg') no-repeat;
  background-size: cover;
  padding: 96px 0 120px;
  margin-bottom: 75px;

  @include media('<widescreen') {
    background-position: -220px 0;
  }

  @include media('<desktop') {
    padding: 0 0 30px;
    background: url('../img/bg-image-sec.jpg') no-repeat !important;
    background-size: cover;
    margin-bottom: 20px;

  }
  .sec-data {
    color: $white;

    @include media('<desktop') {
      padding: 15px 0 0;
    }
  }

  .mobile-img {
    display: none;
    @include media('<desktop') {
      display: block;
      img {
        width: 100%;
      }
    }
  }
}

.groomsmen {
  .logistics-sec {
      background-image: url('../img/groomsman-img1.jpg') ;

      .sec-data {color: $gray3;}

      @include media('<desktop') {
        padding-top: 0;
        background: url('../img/bg-image-sec.jpg') no-repeat ;
        background-size: cover;
        .sec-data {color: $white;}
      }

  }
  .positioning-sec2 {
    padding: 80px 0 100px;

    @include media('<desktop') {
      padding: 40px 0 50px;
    }
  }
  .venues { padding: 0;}
  .venues::after { display: none;}
  .doslider-sec {
    padding: 99px 0 79px;
  }
}



.groomsmen-gallery {
  padding: 124px 0 0;

  .image {
    position: relative;
    margin-top: -70px;
  }

  @include media('<desktop') {
    .sec-data {
      padding: 40px 0 30px;
    }
  }
}
.logistics-sec2 {
  position: relative;
  padding: 100px 0 120px;
  background: url('../img/groomsman-img3.jpg') no-repeat ;
  background-size: cover;
  @include media('<desktop') {
    padding-top: 0;
    background: url('../img/bg-image-sec.jpg') no-repeat ;
    background-size: cover;
  }
  .sec-data {color: $white;}

  .image { 
    display: none;
    margin-bottom: 20px;
    @include media('<desktop') {
      display: block;
      img {
        width: 100%;
      }
    }

  }
}


.featured-box {
  &.right-dir {
    .featured-box__description {
      margin-left: 0;
      margin-right: -125px;

      @include media('<desktop') {
        margin: 0 auto;
       }
    }
  }
  .btn-default {
    float: left;
  }
}
.venue {
  .fade-slider2 {
    &.right-dir {
      .fade-slider2__description {
        margin-left: 0;
        margin-right: -125px;

         @include media('<desktop') {
          margin: 0 auto;
         }
      }
      .fade-slider2__control-box {
        right: auto;
        left: 0;


        .carousel-indicators {
          float: right;

          @include media('<desktop') {
            float: none;
          }
           li {
            float: right;

            @include media('<desktop') {
              float: none;
            }
           }
        }
      }
    }
    .mt100 { margin-bottom: 0;}
  }
  .btn-default {
    float: left;
    margin-top: 0;
    margin-right: 21px;
  }
}

.mt100 {
  margin-top: 100px;
  @include media('<desktop') {
    margin-top: 50px; 
  }
}
.page-mother-two-col{ 
  padding-bottom: 0
}
.page-mother-two-col .sec-data__heading {
  text-indent: 0px
}
.celebrate-sec {
  &.celebratesecmother{
    background: url('../img/mother-3.jpg') #e8eae5;
    background-size: cover;
    @include media('<desktop') {
      background: none !important;
    }
  }
}
.celebratesecmother .sec-data__text {
      color: $gray3 !important;
    }
@include media('<desktop') {
.celebratesecmother img {
  width: 100%;
  height: auto;
  display: block;
  }
}
.bg-none {
  background: none;

  &::after {
    display: none;
  }
}
.personal_concirege .sec-data__heading {
    padding-left: 0;
    letter-spacing: 1px;
    text-align: center;
}
.personal_concirege .team-sec___image-area {
    margin: -50px -132px -20px 0;

    @include media('<tablet') {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 20px;
    }
}
.mar-bottom-80 {
  margin-bottom: 80px;

  @include media('<desktop') {
    margin-bottom: 40px;
  }
}
.padd-bottom-100 {
  padding-bottom: 100px;
  @include media('<tablet') {
    padding-bottom: 50px;
  }
}
.padding-top-none {
  padding-top: 0px
}
.meet-team-visual {
  padding: 89px 0;
  @include media('<desktop') {
    padding: 30px 0;
  }
}

.logistics-sec3 {
  position: relative;
  padding: 100px 0 120px;
  background: url('../img/staff-img-6.jpg') no-repeat ;
  background-size: cover;
  @include media('<desktop') {
    padding-top: 0;
    background: url('../img/bg-image-sec.jpg') no-repeat ;
    background-size: cover;
  }
  .sec-data {color: $white;}

  .image { 
    display: none;
    margin-bottom: 20px;
    @include media('<desktop') {
      display: block;
      img {
        width: 100%;
      }
    }

  }
}

.guest-page {
  .positioning-sec__model {
    top: -50px;
    left: 0;
  }
  .guest-gal {
    @include media('<desktop') {
      padding-bottom: 30px;
    }
    .image2 {
      position: relative;
      margin-left: -60px;
      margin-top: 38px;
      
      @include media('<widescreen') {
        margin-right: -60px;
        margin-top: 100px;
        img {
          max-width: 100%;
          height: auto;
          display: block;
        }
      }
      @include media('<desktop') {
        margin: 20px auto 0;
      }

    }
  }
  .logistics-sec {
    background-image: url('../img/guest4.jpg') ;
    &.add {
        background-image: url('../img/guest5.jpg') ;
        .sec-data { color: $gray3;}
        @include media('<desktop') {
         padding-top: 0;
         background: url('../img/bg-image-sec.jpg') no-repeat ;
         background-size: cover;
         .sec-data { color: $white;} 
        }
      }
  }
}
.no-padding { padding: 0;}
.p-relative { position: relative;}

.staff2 {
  position: relative;
  padding: 100px 0 120px;
  margin-bottom: 90px;
  background: url('../img/staff-img-6.jpg') no-repeat ;
  background-size: cover;
  @include media('<desktop') {
    padding-top: 0;
    background: url('../img/bg-image-sec.jpg') no-repeat ;
    background-size: cover;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
  .sec-data {color: $white;}

  .image { 
    display: none;
    margin-bottom: 20px;
    @include media('<desktop') {
      display: block;
      img {
        width: 100%;
      }
    }

  }
}

@include media('<tablet') {
  .carousel-nav__prev--icon {
    display: block;
    padding-top: 5px;
  }
  .carousel-nav__prev--icon {
    display: block;
    padding-top: 5px;
  }
}

.socialicon .alignleft{margin:0; padding:0;}
.socialicon .alignleft li{
  background: #bfaa80;
  float: left;
  height: 28px;
  margin-left: 13px;
  width: 29px;
  list-style-type: none;
  @include media('<desktop') {
      margin-left: 6px;
  }
}

.socialicon .alignleft li a{
  display: block;
  width: 29px;
  height:28px;
  line-height: 28px;
}

.socialicon .alignleft li svg {
  width: 18px;
  height: 28px;
  color: #fff;
  display: block;
  text-align: center;
  margin:0 auto;
  color: #fff;
  fill:#fff;
}

.socialicon .alignleft li:hover{
  background:#fff;
  cursor: pointer;
}
.socialicon .alignleft li a:hover svg {
  fill:#bfaa80;
}

.single-venue {
  .quote-carousel { margin-top: 0;}
  .quote-carousel__text { margin-top: 0;}
}

.quote-carousel {
  overflow: visible !important;
}
.celebrate-page {
  .positioning-sec__model {
    top: -30px;
    left: 0;
  }
  .galleryslider {
    margin-top: 160px !important;
    
    @include media('<desktop') {
      margin-top: 60px !important;
    }

  }
  .positioning-sec {
    .text-svg {
      top : 90px;
    }
  }
  .celebrate-svg {
    left: auto;
    right: 0;
    bottom: -200px;
  }
}
.img-responsive2 {
  width: 100%;
  height: auto;
  display: block;
}
.client-page {
  .positioning-sec__model {
    top: -30px;
    left: 50px;
  }
  .positioning-sec {
    .text-svg {
      top : auto;
      bottom: 0;
    }
  }
  .celebrate-svg {
    left: auto;
    right: 0;
    bottom: -200px;
  }
  .logistics-sec {
    background: url('../img/client-img2.jpg') no-repeat ;
    background-size: cover;

    @include media('<desktop') {
      background: url('../img/bg-image-sec.jpg') no-repeat ;
      background-size: cover;
    }

    .sec-data__heading {
      position: relative;
      margin-left: -60px;
      margin-right: -60px;

      @include media('<widescreen') {
        margin-left: 0;
        margin-right: 0;
        @include font-size(50px, 55px);
      } 
    }
  }
  .logistics-sec {
    &.add {
      background: url('../img/client-img3.jpg') no-repeat ;
      background-size: cover;
        
      @include media('<desktop') {
        background: url('../img/bg-image-sec.jpg') no-repeat ;
        background-size: cover;
      }
      .sec-data__heading {
        position: relative;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

}

.maitre {
  .logistics-sec {
    background: url('../img/maitred-img3.jpg') no-repeat ;
    background-size: cover;
      
    @include media('<desktop') {
      background: url('../img/bg-image-sec.jpg') no-repeat ;
      background-size: cover;
    }
    .sec-data__heading {
      position: relative;
      margin-left: -60px;
      margin-right: -60px;

      @include media('<widescreen') {
        margin-left: 0;
        margin-right: 0;
        @include font-size(50px, 55px);
      }
    }
  }
}

.galleryslider {
  z-index: 999;
}
.p-bottom-80 { padding-bottom: 80px;}


.page-template-maitre-d {
  .text-svg {
    bottom: auto;
    top: 0;
  }
  .imagine-sec {
    img {
      position: absolute;
      top: 242px;
    }
    ul {
      list-style: none;
      margin: 0 0 38px;
      padding: 0;
      li {
        position: relative;
        padding-left: 15px;
        &::after {
          position: absolute;
          left: 0;
          top: 12px;
          width: 6px;
          height: 6px;
          content: '';
          background: $gray3;
          border-radius: 50%;
        }
      }
    }
    .sec-data { padding-left: 0;}
  }
  .celebrate-sec {
    position: relative;
    z-index: 9;
  }
}

.alm-btn-wrap {
    float: left;
    width: 100%;
}

.page-template-client {
  .two-columns {
    .text-svg { display: none;}
    ul {
      @include listreset;
      text-align: center;
    }
  }
}

.page-template-maitre-d {
  .two-columns {
    .text-svg { display: none;}
    ul {
      @include listreset;
    }
  }
}
.galleryslider img {
  max-height:500px;
}

.page-template-cuisine {
  .positioning-sec2 {
    padding-bottom: 80px;

  }
  .features-tab { z-index: 9999;}
}
.single-plan {
  .schedule-area { padding-bottom: 80px;}
}

.gallery-content {
  .alm-listing  { height: auto !important;}
  .alm-masonry {
    .col-sm-4 {
      float: none !important;
      display: inline-block;
      vertical-align: top;
    }
  }
}

.wide-svg {
  left: -999px;
  right: -999px;
  img {
    width: auto;
    display: block;
    margin: 0 auto; 
  } 
}
#cescaphe_university {
  .wide-svg { display: none !important;}
}

.meet-team-visual { position: relative;}
.page-template-logistics {
  .images-block {
    &.mt100 { margin: 0;}
  } 
}


.fade-slider2__image img {
  max-width: 100%;
  height: 100%;
}

.hs_submit {
  .primary {
    @include font(12px, 16px);
    background: none !important;
    box-shadow: none !important;
    display: block;
    border:1px solid $brown !important;
    width: 163px !important;
    height: 34px !important;
    padding: 8px !important;
    text-transform: uppercase;
    color:$brown !important;
    text-align: center;
    font-weight: 400;
    border-radius: 0;
    transition: all 0.4s ease;
    letter-spacing: 0;
    &:hover { 
      background: $brown !important;
      color:$white !important;
      border-color: $brown !important;
      text-decoration: none;
    }
  }
}

#planner {
  .sec-data__text { padding: 0 10%;}
}

.page-template-plan {
  .meet-sec__image {
    bottom: -57px;

   @include media('<extrawidescreen') {
    bottom: -22px;
   }
   @include media('<widescreen') {
    bottom: -69px;
   } 
  }
  @include media('<extrawidescreen') {
    .comman-heading span::after { right: -20px;}
  }

  .positioning-sec2 {
    padding-bottom: 80px;
  }
  .images-block {
    position: relative;
    z-index: 9999;
    &.mt100 { margin: 0;}
  }
  .meet-sec { padding-top: 0;}
  .two-columns {
    .text-svg { display: none;}
  } 
}
.testimonials-no-padding {
  .testimonialslider {
    padding: 0 !important;
  }
}

.page-template-details {
  .meet-sec { margin-top: 40px;}
}

input {
  outline: none;
}
.single-post .entry-content img { 
  margin-top: 30px;
}
.entry-content-blog img { 
  margin-top: 30px;
}
.single-post .schedule-area {
  padding-bottom: 90px;
}
.entry-content-blog p {
    color: #777;
    font-family: "proxima-nova";
    font-size: 13px;
    letter-spacing: 1.5px;
    line-height: 30px;
    clear: both;
}

.mobi-content {
  display: none !important;

  @include media('<desktop') {
    display: block !important;
  }
  .venues-list {
    .open-menu2 { 
      display: block !important;
      float: none;
      width: 100%;
    }
    .subdropdown {
      position: static;
      width: 100%;
      display: none;
      &.in {
        display: block;
      }
      .subdropdown__holder2 {
        width: 100%;
        padding: 0 0 10px;
      }
    }
  }
}

.gform_confirmation_message {
  color: green;
}

.page-template-bride {
  .text-left {
      text-align: left !important;
      @include media('<desktop') {
        text-align: center !important;
      }
  }
}

.page-template-Bridesmaids {
  .two-columns {
    .text-svg {  display: none;}
  }
  .logistics-sec {
    .sec-data__text { padding: 0 5%;}
  }
}

.featured-box__description p {
    color: #777;
    font-size: 18px;
    line-height: 27px;
    margin-top: 18px;
    font-family: "proxima-nova-condensed",sans-serif;
}
.page-template-decore {
  .images-block { 
    &.mt100 {
      margin: 0 !important;
    }
  }
}

.page-template-decore {
  .two-columns {
    .text-svg {
      bottom: auto;
      top: 29%;
    }
  }
}

.position__location p {
    color: #777 !important;
    font-family: "Oswald" !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 30px !important;
}
.position p {
  color: #777;
    font-family: "proxima-nova";
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
}
.text-left {
  text-align: left;
}
.page-template-celebrate #testimonials_slider .quote-carousel__title span {
    display: inline-block;
    padding: 0 35px;
    position: relative;
    vertical-align: top;
}
.page-template-celebrate #testimonials_slider .quote-carousel__title span:before {
    background: #bfaa80 none repeat scroll 0 0;
    content: "";
    height: 1px;
    margin: 0 0 0 -9999px;
    padding: 0 0 0 9999px;
    position: absolute;
    left: 0;
    top: 57%;
    z-index: -99;
}
.page-template-celebrate #testimonials_slider .quote-carousel__title span:after {
    background: #bfaa80 none repeat scroll 0 0;
    content: "";
    height: 1px;
    margin: 0 -9999px 0 0;
    padding: 0 9999px 0 0;
    position: absolute;
    right: 0;
    top: 57%;
    z-index: -99;
}
.page-template-celebrate #testimonials_slider .quote-carousel {
  overflow: visible;
}

.page-template-event-manager {
  .positioning-sec {
    min-height: 555px;
    @include media('<extrawidescreen') {
      min-height: inherit;
      padding-bottom: 20px;
    }


    .text-svg {
      top: auto;
      bottom: 0;
    }
  }
  .logistics-sec {
    z-index: 999 !important;
  }
}

.heading-wide {
  margin-right: -20px;
  margin-left: -20px;
}

ul { padding-left: 0;}

.page-template-father {
  .visual-internal-nav {
    right: -60px;
  }
}

.page-template-gallery {
  .visual-internal-nav {
    right: -60px;
  }
}

@include media('<tablet') {
  .gallery-row .open-menu2 i::after {
    width: 16px;
  }
  .gallery-row .open-menu2 i::before {
    width: 16px;
  }
}

.page-template-groom {
  .visual-internal-list {
    width: 125px;
  }
  .visual-internal-nav {
    right: -55px;
  }
  .celeb-sec-image.groom-plan {
    z-index: 999;
  }
  .positioning-sec2 {
    .sec-data {
      z-index: inherit;
    }
    .sec-data__text {
      position: relative;
      z-index: 9999;
    }
  }
}

.text-svg {
  &.bottom-dir {
    top: auto;
    bottom: 0;
  }
  &.left-dir {
    right: auto;
  }
  &.top {
    bottom: auto;
    top: 190px;
  }
  &.align-top {
    bottom: auto;
    top: -90px;
  }
}
.page-template-Bridesmaids {
  .text-svg {
    &.bottom-dir {
      bottom: -90px;
    }
  }
  .gallery-sec {
    .text-svg {
    &.bottom-dir {
        bottom: -290px;
      }
    }
  }
}
.groomsmen {
  .text-svg {
    &.bottom-dir {
      bottom: -89px;
    }
  }
}
.page-template-guest {
  .positioning-sec {
    .sec-data {
      position: relative;
      z-index: inherit;
    }
    .sec-data__text {
      position: relative;
      z-index: 9999;
    }
  }
}


#hbspt-form-1516010756799-1792825878 {
    width: 100%;
    margin-bottom: 100px;
}

#quote-carousel.carousel-inner .item {
opacity: 0;
-webkit-transition-property: opacity;
-moz-transition-property: opacity;
-o-transition-property: opacity;
transition-property: opacity;
}
#quote-carousel .carousel-inner .active {
opacity: 1;
}
#quote-carousel .carousel-inner .active.left,
#quote-carousel .carousel-inner .active.right {
left: 0;
opacity: 0;
z-index: 1;
}
#quote-carousel .carousel-inner .next.left,
#quote-carousel .carousel-inner .prev.right {
opacity: 1;
}
.fade-slider2__description--heading {
  overflow: hidden;
}
.page-template-details .mt100 {
  margin-top: 0 !important;
}

.recipeholder {
  .comman-heading {
    @include media('<tablet') {
      font-size: 30px;
      line-height: 33px;
    }
  }
}
.entry-content-blog img {
  display: block;
}
.entry-content-blog p img {
  display: inline-block;
}
.entry-content-blog ul{
    color: #777;
    font-family: "proxima-nova";
    font-size: 13px;
    letter-spacing: 1.5px;
    line-height: 30px;
    margin-left: 12px;
}
.sec-data ul {
  text-align: left;
}
.afternone span:after { 
  display:none;
  @include media('<desktop') {
    display: block ;
  }
}
.beforenone span:before { 
  display:none;
  @include media('<desktop') {
    display: block ;
  }
}
.tab-content .tab-image img {
  max-height: 533px;
  object-fit:cover;
  width: 100%;
}


.page-template-guest {
  .venues {
    .comman-heading {
      span::before { 
        display: none;
        @include media('<extrawidescreen') {
          display: block ;
        }
      }
    }
  }
}
.page-template-groom {
  .venues {
    .comman-heading {
      span::after { display: none;}
    }
  }
}



.single-venue  {
  .fade-slider2__description--text {
    font-size: 14px;
    line-height: 20px;
  }
  .fade-slider2__control-box { bottom: 20px;}
}
.page-template-staff {
    .testimonialslider { padding-top: 20px;}
}

.page-template-guest {
  .sec-data__heading .border-line {
    margin-left: 90px;
    &:before { display: none;}
  }
}

.fade-slider2__description {
  .right-bar {
    span { padding-left: 0 !important;}
  }
}

.home {
  .max-container {
    max-width: 100%;
  }
}
.full-page-img img {
  width: 100%;
}

.page-template-logistics .fade-slider2__image img {
  width: 100%;
}

.page-template-wedding-party {
  .venues  {
    padding-top: 0;
    &:after { display: none;}
  }
  .text-svg {
    &.bottom-dir {
      bottom: -189px;
    }
  }
}
.entry-content-blog {
  img {
    display: block;
    max-width: 100%;
    margin: 15px auto;
  }

  span {
    img {
      display: inline-block;
      vertical-align: top;
    }
  }
}
.page-template-guest {
  .text-svg {
    &.bottom-dir {
      bottom: -390px;
    }
    &.right-dir {
      top: 0;
      bottom: auto;
    }
  }
  .positioning-sec {
    .sec-data__heading {
      z-index: 999999;
      @include media('<desktop') {
        text-align: center;
        span {
          margin-left: 0;
          &:before { display: block;}
        }
      }
    }
  }
}
.navdescription {
  .carousel { width: 100%;}
}

.gallery-content__image button {
  border: 0;
  padding: 0;
  width: 100%;
}
.page-id-1187 .celeb-sec-image .sec-data__text {
  text-shadow:0 0 20px #fff, 0 0 20px #fff, 0 0 20px #fff, 0 0 20px #fff, 0 0 20px #fff, 0 0 20px #fff, 0 0 20px #fff, 0 0 20px #fff, 0 0 20px #fff, 0 0 20px #fff, 0 0 20px #fff, 0 0 20px #fff, 0 0 20px #fff, 0 0 20px #fff, 0 0 20px #fff, 0 0 20px #fff
}
.page-template-groomsmen #cuisine .sec-data__heading {
  text-shadow:0 10px 30px #000, 0 10px 30px #000;
}
.page-template-wedding-party .testimonialslider {
  padding-top: 0 !important;
}
.page-template-wedding-party .quote-carousel {
  margin-top: 0 !important;
}
.gallery-holder .carousel-inner img {
  max-width: auto !important;
  height: auto;
  margin: 0 auto;
}
.gallery-row__load-more-button--all-loaded { 
  opacity: 0.5; 
  cursor: default;
  pointer-events: none;
}
.venue .btn-default {
  margin-bottom: 11px;
}
.page-id-2994 ol li{
  text-align: left;
}
.page-id-2994 .entry-content p {
  text-align: left;
}
.text-justify-left,
.text-justify-left span {
  text-align: left;
  margin-left: 0 !important;
  padding-left: 0 !important;
}
.navinfocontent__close-button {
  color: #bfaa80;
  font-family: 'proxima-nova',sans-serif;
  font-size: 30px;
  font-weight: 200;
  line-height: 20px;
  position: absolute;
  right: 30px;
  top: -30px;

  &:hover {
    color: #000;
    cursor: pointer;
  }
}
.navinfocontent {
  position: relative;
}
.page-template-rsvp .schedule-form .hbspt-form {
  width: 100% !important;
}
.page-template-rsvp .schedule-form .hbspt-form .form-columns-3{
  width: 100%;
  max-width: 100% !important;
}
.page-template-rsvp .schedule-form .hbspt-form .hs_venue,
.page-template-rsvp .schedule-form .hbspt-form .hs_number_attending {
  width: 49%
}
.page-template-rsvp .schedule-form .hbspt-form .hs_wedding_date,
.page-template-rsvp .schedule-form .hbspt-form .hs_ceg_university_attending {
  width: 49%;
  float: right;
}
.page-template-rsvp .schedule-form .hbspt-form .hs_ceg_university_attending ul {
  padding-left: 0 !important;
}
.page-template-rsvp .schedule-form .hbspt-form .hs_ceg_university_attending ul li label span {
  position: relative;
  top: -1px;
}
@media screen and ( max-width: 600px ) {
  .page-template-rsvp .schedule-form .hbspt-form .hs_venue,
  .page-template-rsvp .schedule-form .hbspt-form .hs_number_attending,
  .page-template-rsvp .schedule-form .hbspt-form .hs_wedding_date,
  .page-template-rsvp .schedule-form .hbspt-form .hs_ceg_university_attending {
    width: 100%;
    float: none;
  }
}
.navinfo__holder {
  cursor: default !important;
}
/* stylelint-disable */
body {
  @include font-size(16px, 1.5);
  background: $white;
  font-family: 'proxima-nova',sans-serif;
  min-width: 320px;
}
::-webkit-input-placeholder { 
  color: $gray3;
}
:-moz-placeholder { 
  color: $gray3;
}
::-moz-placeholder { 
  color: $gray3;
  opacity: 1;
}
:-ms-input-placeholder { 
  color: $gray3;
}
.contact1 ul li.date {
  ::-webkit-input-placeholder { 
    color: $white;
  }
  :-moz-placeholder { 
    color: $white;
  }
  ::-moz-placeholder { 
    color: $white;
    opacity: 1;
  }
  :-ms-input-placeholder { 
    color: $white;
  }
}
.searchholder {
  ::-webkit-input-placeholder { 
    color: #dbdbdb;
  }
  :-moz-placeholder { 
    color: #dbdbdb;
  }
  ::-moz-placeholder { 
    color: #dbdbdb;
    opacity: 1;
  }
  :-ms-input-placeholder { 
    color: #dbdbdb;
  }
}
.contact-detail-form {
  ::-webkit-input-placeholder { 
    color: #777;
  }
  :-moz-placeholder { 
    color: #777;
  }
  ::-moz-placeholder { 
    color: #777;
    opacity: 1;
  }
  :-ms-input-placeholder { 
    color: #777;
  }
}
a { 
  color: #bfaa80;
  transition: all 0.4s ease;
}
a:focus {
  text-decoration: none;
  color: inherit;
}
 a:hover, a:focus {
  color: inherit;
 }
@font-face {
  font-family: "cescaphe-icons";
  src:url("fonts/cescaphe-icons.eot");
  src:url("fonts/cescaphe-icons.eot?#iefix") format("embedded-opentype"),
    url("fonts/cescaphe-icons.woff") format("woff"),
    url("fonts/cescaphe-icons.ttf") format("truetype"),
    url("fonts/cescaphe-icons.svg#untitled-font-3") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "cescaphe-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "cescaphe-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-telephone:before {
  content: "\61";
}
.icon-facebook:before {
  content: "\62";
}
.icon-location:before {
  content: "\63";
}
.icon-mail:before {
  content: "\65";
}
.icon-twitter:before {
  content: "\66";
}
.icon-instagrem:before {
  content: "\64";
}
.icon-youtube:before {
  content: "\67";
}
.icon-linkedin:before {
  content: "\68";
}
.icon-google-plus:before {
  content: "\69";
}
.icon-github:before {
  content: "\6a";
}
.icon-dribbble:before {
  content: "\6b";
}
.icon-search:before {
  content: "\6c";
}
.icon-pinterest-p:before {
  content: "\6e";
}
.icon-twitter-1:before {
  content: "\6f";
}
.icon-youtube-play:before {
  content: "\70";
}
.icon-facebook-official:before {
  content: "\71";
}
.icon-align-right:before {
  content: "\72";
}
.icon-globe:before {
  content: "\73";
}
.icon-file-text:before {
  content: "\74";
}
.icon-heartbeat:before {
  content: "\75";
}
.icon-right-thin:before {
  content: "\76";
}
.icon-device-mobile:before {
  content: "\77";
}
.icon-tag:before {
  content: "\78";
}
.icon-times-circle:before {
  content: "\79";
}
.icon-left-thin:before {
  content: "\7a";
}
.icon-right-open:before {
  content: "\41";
}
.icon-right-open-big:before {
  content: "\42";
}
.icon-left-open-mini:before {
  content: "\43";
}
.icon-left-open-big:before {
  content: "\44";
}
.icon-up-open-big:before {
  content: "\45";
}
.icon-up-open:before {
  content: "\46";
}
.icon-down-open-big:before {
  content: "\47";
}
.icon-down-open-mini:before {
  content: "\48";
}
.icon-down-open:before {
  content: "\49";
}
.icon-user:before {
  content: "\4a";
}
.icon-user-md:before {
  content: "\4b";
}
.icon-check:before {
  content: "\4c";
}
.icon-linkedin-alt:before {
  content: "\4d";
}
.icon-yelp:before {
  content: "\4e";
}
.icon-houzz:before {
  content: "\6d";
}
.icon-vimeo:before {
  content: "\4f";
}
.icon-skype:before {
  content: "\50";
}
.icon-play:before {
  content: "\51";
}
.icon-align-justify:before {
  content: "\52";
}
.icon-chevron-left:before {
  content: "\53";
}
.icon-chevron-right:before {
  content: "\54";
}
.icon-pause:before {
  content: "\55";
}

input[type="submit"]:hover {
  background: $brown;
  color: $white;
}
.btn {
  @include font(14px, 30px);
  background: $light-blue;
  display: block;
  padding: 10px;
  color:$white;
  text-align: center;
  border: 1px solid $light-blue;
  font-weight: 400;
  border-radius: 1px;
  transition: all 0.4s ease;
  letter-spacing: 2px;
  &:hover { 
    background: none;
    color:$dark-green;
  }
}
.btn-primary {
  @include font(12px, 24px);
  background: $white;
  float: right;
  width: 100px;
  height: 30px;
  padding: 0;
  color:$light-blue;
  text-align: center;
  border:3px solid $light-blue;
  border-radius: 0;
  font-weight: 500; 
  transition: all 0.4s ease;
  letter-spacing: 0;
  &:hover { 
    background: $blue;
    color:$yellow;
    border-color: $blue;
    text-decoration: none;
  }
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { 
  font-weight: 700;
}
p {
  margin: 30px 0 0;
}
.btn-default {
  @include font(12px, 16px);
  background: none;
  box-shadow: none !important;
  float: left;
  border:1px solid $brown;
  width: 163px;
  height: 34px;
  padding: 8px;
  text-transform: uppercase;
  color:$brown;
  text-align: center;
  font-weight: 400;
  border-radius: 0;
  transition: all 0.4s ease;
  letter-spacing: 1px;
  font-family: 'Oswald', sans-serif;
  &:hover { 
    background: $brown;
    color:$white !important;
    border-color: $brown;
    text-decoration: none;
  }
  &:after { display: none !important;}
}
.direction_btn {
  @include font(12px, 16px);
  background: none;
  box-shadow: none !important;
  display: block;
  margin: 40px auto 0;
  border:1px solid $brown;
  width: 163px;
  height: 34px;
  padding: 8px;
  text-transform: uppercase;
  color:$brown;
  text-align: center;
  font-weight: 400;
  transition: all 0.4s ease;
  letter-spacing: 0;
  &:hover { 
    background: $brown;
    color:$white !important;
    border-color: $brown;
    text-decoration: none;
  }
}
.container {
  max-width: 1176px ;
  width: 100% !important;
}
.max-container {
  max-width: 1600px ;
  margin: 0 auto;
  position: relative;
}
.max-container:after {
  display: block;
  clear: both;
  content: "";
}

body:not(.home){
  .header {
    position: relative;
  }
}

.comman-heading {
  position: relative;
  text-align: center;
  @include font-size(80px, 83px);
  color: $brown;
  font-family: 'tablet-gothic-compressed',sans-serif;
  font-weight: 200;
  margin: 0 0 33px;
  span {
    display: inline-block;
    vertical-align: top;
    padding: 0 35px;
    position: relative;

    &:after {
      position: absolute;
      right: 0;
      top: 57%;
      content: '';
      background: $brown;
      height: 1px;
      padding: 0 9999px 0 0;
      margin: 0 -9999px 0 0;
    }

    &:before {
    position: absolute;
    left: 0;
    top: 57%;
    content: '';
    background: $brown;
    height: 1px;
    padding: 0 0 0 9999px;
    margin: 0 0 0 -9999px;
  }
  }
}

.text-svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
}
.testimonialslider {
  .text-svg {
    bottom: -46%;
  }
}
.bride-svg {
  bottom: auto;
  top: 5%;
}
.team2-svg {
  bottom: auto;
  top: -18%;
  left: -20%;
}

.entry-content {
  h3 {
    @include font-size(40px, 46px);
    color: $brown;
    font-family: 'tablet-gothic-compressed',sans-serif;
    font-weight: 400;
  }
  .aligncenter {
    max-width: 100%;
    display: block;
    margin: 15px auto 0; 
  }
  .alignleft {
    float: left;
    margin: 15px 25px 30px 0; 
  }
  .alignright {
    float: left;
    margin: 15px 0 30px 25px; 
  }
  .clear { clear: both;}
  
}

.js-slide-hidden {
    position: static !important;
    left: -9999px !important;
    top: -9999px !important;
    display: block !important;
}
/* stylelint-enable */
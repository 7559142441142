// Custom styles here
// Note, if this file exceeds 200 lines of code, it must be broken up into multiple files and imported using @import


$breakpoints: (
  'xs-phone': 320px,
  'phone': 480px,
  'tablet': 768px,
  'desktop': 992px,
  'widescreen': 1200px,
  'extrawidescreen': 1500px
);

// keywords
$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);


@import 'header';
@import 'logo';
@import 'navigation';
@import 'visual';
@import 'footer';
@import 'form-area';
@import 'slider-testimonial';
@import 'visual-internal';
@import 'events-black';
@import 'bride-sec';
@import 'cegmain';
@import 'contact';
@import 'careers';
@import 'mislider';
@import 'blog';
@import 'bridesmaids';
@import 'chef';
@import 'cuisine';
@import 'decor';
@import 'schedule';
@import 'gallery';
@import 'recipe';
@import 'genral';
@import 'gravity-forms-updates';
@import 'neeraj-styles';


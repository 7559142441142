/* stylelint-disable */

.ajax-load-more-wrap .alm-listing li.grid-item{
        width: 30%;
        margin: 0 1.5% 5px;
        float: left;
        display: inline;
    }
    .ajax-load-more-wrap .alm-listing li {
        padding: 0 !important;
    }
    .ajax-load-more-wrap .alm-btn-wrap {
      display: grid;
      padding: 10px 0 25px;
    }
    .ajax-load-more-wrap .alm-btn-wrap .alm-load-more-btn{
      margin: 0 auto;
      width: 163px;
    }
    .blogpost a {
      padding: 15px 10px 26px;
    }
    @media screen and (max-width: 480px){
       .ajax-load-more-wrap .alm-listing li.grid-item{
          width: 100%;
          margin: 0 0 20px;
       }
    }


    .pagination.custom-pagination .page-numbers{
  font-size: 20x;
  line-height: 22px;
  border-radius: 0px !important;
  color: #bfaa80;
  font-family: 'tablet-gothic-compressed',sans-serif;
  font-weight: 200;
  border:1px solid #bfaa80 !important;
  padding: 0;
  height: 50px;float: left;
  line-height: 48px !important;
  width: 50px;
  text-align: center;
}

    .pagination.custom-pagination .page-numbers.current{background: #bfaa80;
color: #fff;
border-color: #bfaa80;}
.gallery-row{
    float: left;
  width: 100%;
}


#n2-ss-2 {
    width: 100% !important;
}
.n2-ss-slider-1.n2-ss-swipe-element.n2-ow.n2-grab {
    width: 100% !important;
}

.page-template-maitre-d {
  .logistics-sec { 
    position: relative;
    z-index: 9999;
  }
}
.subheader {
    margin-top: 29px;
    text-decoration: underline;
    font-size: 18px;
}

.alm-load-more-btn.done {
    display: none;
}

/** @define visual-internal */
.visual-internal {
  padding: 0 75px;
  position: relative;
  z-index: 999;
  
  @include media('<desktop') {
    padding: 0 25px; 
  }
  
  @include media('<tablet') {
    padding: 0 15px;
  }

  &__image {
    display: block;

    &--img {
      height: auto;
      width: calc(100% - 91px);

      @include media('<widescreen') {
        width: calc(100% - 140px);
      }

      @include media('<tablet') {
        margin-bottom: 60px;
        width: 100%;
      }
    }
  }
}

/** @define visual-internal-nav */
.visual-internal-nav {
  bottom: -8px;
  position: absolute;
  right: -55px;

  @include media('<desktop') {
    right: 0;
  }

  @include media('<tablet') {
    bottom: auto;
    left: 0;
    position: relative;
    right: 0;
  }
}
/** @define visual-internal-list */
.visual-internal-list {
  display: block;
  margin-bottom: 0;
  width: 125px;

  @include media('<widescreen') {
    width: 170px;
  }

  @include media('<desktop') {
    width: 125px;
  }

  @include media('<tablet') {
    border-bottom: 1px solid $brown;
    padding-bottom: 20px;
    width: 100%;
  }

  &__item {
    @include font-size(14px, 18px);
    @include listreset;
    color: $gray3;
    font-family: 'Oswald';
    letter-spacing: 0.5px;
    margin-top: 36px;
    padding-bottom: 7px;
    position: relative;

    @include media('<extrawidescreen') {
      margin-top: 15px;
    }
    
    @include media('<widescreen') {
      margin-top: 7px;
    }

    @include media('<desktop') {
      margin-top: 5px;
    }

    @include media('<tablet') {
      margin-top: 28px;
    }

    &::after {
      background: transparent;
      bottom: 0;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      width: 27px;
    }

    /* stylelint-disable */
      &:hover,
      &.active {
        &:after {
          background: $brown; 
        }  
      }
    /* stylelint-enable */

    &--a {
      color: #777;
      text-decoration: none;

      &:hover {
        color: #000;
        text-decoration: none;
      }
    }
  }
}
/* stylelint-disable */
.visual-internal__image {
  #n2-ss-2-align {
    width: calc(100% - 91px);

    @include media('<widescreen') {
      width: calc(100% - 140px);
    }

    @include media('<tablet') {
      margin-bottom: 60px;
      width: 100%;
    }
  }
}

.visual-internal__image {
  img {
    height: auto;
    width: calc(100% - 91px);

    @include media('<widescreen') {
      width: calc(100% - 140px);
    }

    @include media('<tablet') {
      margin-bottom: 60px;
      width: 100%;
    }
  }
}
.visual-internal {
  .collapse { display: block;}
  .collapse.in { display: block;}

  @include media('<tablet') {
    .collapse { display: none;}
  }
}
.visual-internal-nav {
  .open-menu {
    @include font-size(20px, 23px);
    border-top: 1px solid $brown;
    height: 53px;
    font-family: 'tablet-gothic-compressed',sans-serif;
    text-transform: uppercase;
    font-weight: 200;
    color: $brown;
    box-sizing: border-box;
    display: none;
    position: relative;
    
    @include media('<tablet') {
      display: block;
    }
    span {
      display: inline-block;
      vertical-align: top;
      padding: 15px 0 15px 18px;
    }

    i {
      position: absolute;
      display: block;
      right: 18px;
      top: 50%;
      width: 17px;
      height: 17px;
      margin-top: -2px;
      transition:all 0.2s linear;

      &:before {
        position: absolute;
        width: 17px;
        height: 1px;
        content: '';
        background: $brown;
        left: 0;
        transition:all 0.2s linear;
        transform:rotate(45deg);
      }

      &:after {
        position: absolute;
        width: 17px;
        height: 1px;
        content: '';
        background: $brown;
        left: 0;
        transition: all 0.2s linear;
        transform: rotate(-45deg);
      } 
    }
    &.collapsed {
      border-bottom: 1px solid $brown;
      i {
        &:before {
          transform: rotate(90deg);
        }

        &:after {
          transform: none;
        }
      }
    }
  }
}

/** @define fade-slider2 */
.fade-slider2 {
  position: relative;

  &__image {
    position: relative;
    z-index: 9;
  }

  &__description {
    background: $white;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    height: 400px;
    margin-left: -125px;
    padding: 10px 60px;
    position: relative;
    width: 589px;
    z-index: 999;

    @include media('<tablet') {
      height: auto;
      padding: 25px 20px;
    }

    &--heading {
      @include font-size(50px, 53px);
      color: $brown;
      display: table;
      font-family: 'tablet-gothic-compressed',sans-serif;
      font-weight: 200;
      margin: 0;
      position: relative;
      text-transform: uppercase;
    }

    &--text {
      @include font-size(17px, 25px);
      color: $gray3;
      font-family: 'proxima-nova-condensed',sans-serif;
      letter-spacing: 1.5px;
      margin-top: 18px;
    
      @include media('<tablet') {
        @include font-size(14px, 20px);
        margin-top: 10px;
      }
    }
  }
}



/* stylelint-disable */ 
.fade-slider2__description {
  .inner { width: 100%;}
}
.align-content2 {
  align-items: center;
  display: flex;
  justify-content: center;
}
.two-columns { position: relative;}
.fade-slider2__control-box {
  position: absolute;
  right: 0;
  width: 40%;
  bottom: 32px;
  z-index: 9999;

  @include media('<widescreen') {
    width: 26%;
  }
  .carousel-indicators {
    margin: 0;
    padding: 0;
    position: static;
    li {
      float: left;
      width: 12px;
      height: 12px;
      box-sizing: border-box;
      border: 1px solid $brown;
      font-size: 0;
      line-height: 0;
      margin: 0 0 0 16px !important;
      border-radius: 0 !important;
      &:hover {
        width: 12px;
        height: 12px;
        margin: 0 0 0 16px !important;
        background-color : $brown !important;
      } 
    }
  }
}
.fade-slider2 {
  .carousel-indicators .active {
    width: 12px;
    height: 12px;
    margin: 0 0 0 16px !important;
    background-color : $brown !important;
  }
}

.meet-sec {
  .sec-data__heading {
    &.large {
      position: relative;
      margin-right: -90px;
      margin-left: -90px;

      @include media('<extrawidescreen') {
        @include font(24px, 30px);
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}
.meet-sec__image {
  z-index: 999;
  &.right {
    left: auto;
    right: 0;
  }

  @include media('<desktop') {
    position: static;
    border-bottom: 1px solid $brown;
    img {
      margin: 0 auto;
    }
  }
}

.fade-slider2 {
  .carousel-fade .carousel-control { z-index: 2; }  
  .carousel-fade .item {
    opacity: 0;
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
    }

    .carousel-fade .next.left,
    .carousel-fade .prev.right,
    .carousel-fade .item.active { opacity: 1; }

    .carousel-fade .active.left,
    .carousel-fade .active.right {
      left: 0;
      opacity: 0;
      z-index: 1;
  }
  @media all and (transform-3d), (-webkit-transform-3d) {
      .carousel-fade .carousel-inner > .item.next,
      .carousel-fade .carousel-inner > .item.active.right {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
      }
      .carousel-fade .carousel-inner > .item.prev,
      .carousel-fade .carousel-inner > .item.active.left {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
      }
      .carousel-fade .carousel-inner > .item.next.left,
      .carousel-fade .carousel-inner > .item.prev.right,
      .carousel-fade .carousel-inner > .item.active {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
      }
  }
  .carousel-inner {
      z-index: 999;
    }
}
@include media('<widescreen') {
  .images-block {
      position: relative;
      z-index: 999;
    }
}
@include media('<desktop') {
    .dispflex {
      display: block;
    }
    .fade-slider2__image {
      img {
        width: 100%;
        display: block;
        margin-bottom: 20px;
        height: auto;
      }
    }
    .fade-slider2__description {
      margin: 0 auto;
    }
    .fade-slider2__control-box {
      position: static;
      width: 100%;
      padding: 15px 0 0;
    }
    .fade-slider2__control-box .carousel-indicators {
      text-align: center;
      width: 100%;
      position: static;
    }
    .fade-slider2__control-box .carousel-indicators li {
      display: inline-block;
      vertical-align: top;
      float: none;
      margin: 0 8px !important;
    }
    .fade-slider2__control-box .carousel-indicators .active {
      margin: 0 8px !important;
    }

    .fade-slider2__control-box .carousel-indicators li:hover {
      margin: 0 8px !important;
    }
  
}
.fade-slider2__description--heading:after {
    background: #bfaa80;
    content: "";
    height: 1px;
    margin: 0 -10030px 0 0;
    padding: 0 9999px 0 0;
    position: absolute;
    right: 0;
    top: 57%;
    z-index: -99;
}
.right-dir {
  .fade-slider2__description--heading:after {
    background: #bfaa80;
    content: "";
    height: 1px;
    margin: 0 0 0 -9999px;
    padding: 0 0 0 9999px;
    position: absolute;
    left: -22px;
    right: auto;
    top: 57%;
    z-index: -99;
  }
}
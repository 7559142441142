/** @define positioning-sec */
.positioning-sec {
  padding: 53px 0 120px;
  position: relative;
    
  @include media('<widescreen') {
    padding-bottom: 60px;
  }

  &__model {
    left: 36px;
    position: absolute;
    top: 18px;
    z-index: 999;
    
    @include media('<extrawidescreen') {
      width: 300px;
    }

    @include media('<widescreen') {
      left: -66px;
    }

    @include media('<desktop') {
      display: none;
    }

    &--image {
      display: block;
    }
  }
}

/** @define sec-data */
.sec-data {
  @include font-size(14px, 30px);
  color: $gray3;
  font-family: 'proxima-nova',sans-serif;
  position: relative;

  &__heading {
    @include font-size(80px, 83px);
    color: $brown;
    font-family: 'tablet-gothic-compressed',sans-serif;
    font-weight: 200;
    letter-spacing: 3px;
    margin: 0 0 42px;
    text-indent: 0;


    @include media('<tablet') {
      @include font-size(45px, 50px);
    }
  }

  &__text {
    letter-spacing: 0.7px;
    margin: 0 0 28px;
    position: relative;
    z-index: 999;
  }

  &__image {
    margin-top: 82px;
    position: relative;
    z-index: 99;

    @include media('<widescreen') {
      width: 90%;
    }

    @include media('<desktop') {
      margin-top: 10px;
    }

    @include media('<tablet') {
      width: 85%; 
    }

    &::after {
      background: rgba(191, 170, 128, 0.9);
      bottom: 0;
      content: '';
      left: -31px;
      position: absolute;
      top: 0;
      width: 31px;
      z-index: 9;

      @include media('<tablet') {
        left: -15px;
        width: 15px;
      }
    }

    &--img {
      display: block;
      height: auto;
      width: 100%;
    }
  }
}

/** @define plan-sec */
.plan-sec {
  @include font-size(14px, 30px);
  font-family: 'proxima-nova',sans-serif;
  letter-spacing: 0.5px;
  padding: 82px 0 111px;
  position: relative;

  @include media('<widescreen') {
    padding: 60px 15px; 
  }

  &__image2 {
    position: absolute;
    right: -211px;
    top: -147px;
    z-index: -1;

    @include media('<widescreen') {
      top: -40px;
    }

    @include media('<tablet') {
      display: none;
    }

    &--img2 {
      display: block;
    }
  }

  &__head {
    @include font-size(80px, 83px);
    color: $brown;
    font-family: 'tablet-gothic-compressed',sans-serif;
    font-weight: 200;
    margin: 0;
  }

  &__des {
    color: $gray3;
    letter-spacing: 1px;
    margin-top: 47px;
  }
}

/** @define celebrate-sec */
.celebrate-sec {
  background-size: cover;
  color: $white;
  padding: 85px 0 114px;
  position: relative;
  
  @include media('<extrawidescreen') {
    background-position: -110px 0;
  }

  @include media('<widescreen') {
    background-position: -220px 0;
  }

  @include media('<desktop') {
    background: url('../img/bg-sec-celeb.png') #535558;
    padding: 0 0 50px;
  }

  &__image3 { 
    display: none;

    @include media('<desktop') {
      display: block;
      margin: 0 -20px 20px;
      position: relative;
      width: 113%;
      
    }
  }
}

/** @define venues */
.venues {
  @include font-size(14px, 30px);
  font-family: 'proxima-nova',sans-serif;
  letter-spacing: 0.5px;
  padding: 85px 0 88px;
  position: relative;

  @include media('<desktop') {
    padding-bottom: 20px;
  }

  @include media('<tablet') {
    padding: 40px 0 30px;
  }

  &__sec-head {
    @include font-size(80px, 83px);
    color: $brown;
    font-family: 'tablet-gothic-compressed',sans-serif;
    font-weight: 200;
    letter-spacing: 3px;
    margin: 0 0 60px;
    text-indent: 0;

    @include media('<tablet') {
      @include font-size(30px, 33px);
      margin: 0 0 25px;
    }
  }
}

/** @define team-sec */

.team-sec {
  padding: 0;
  position: relative;

  &__image-area {
    margin: -102px -98px -20px -113px;
    position: relative;
  }
}

/** @define doslider-sec */
.doslider-sec {
  padding-bottom: 80px;
  position: relative;
}


















 /* stylelint-disable */
.celebrate-sec {
  @include media('<desktop') {
    background: url('../img/bg-sec-celeb.png') #535558 !important;
    padding: 0 0 50px;
  }
}
.imagine-sec {
  .sec-data {
    padding: 0 0 0 66px;
    @include media('<widescreen') {
      padding: 0;
    }
  }
  .sec-data__heading {
    margin-top: 85px;
    text-indent: 0 !important;

    @include media('<widescreen') {
      margin:20px 0 30px; 
    }
  }
  .sec-data__text { 
    margin: 0 0 35px;
  }
}
.sec-data__heading {
  position: relative;
  .border-line {
    display: inline-block;
    vertical-align: top;
    position: relative;
    z-index: 9;
    padding: 0 37px;

    &:before {
      position: absolute;
      left: 0;
      top: 57%;
      content: '';
      height: 1px;
      background: $brown;
      padding: 0 0 0 9999px;
      margin: 0 0 0 -9999px; 
    }

    &:after {
      position: absolute;
      right: 0;
      top: 57%;
      content: '';
      height: 1px;
      background: $brown;
      padding: 0 9999px 0 0;
      margin: 0 -9999px 0 0; 
    }
  }
}
 
.positioning-sec {
  .max-conatiner { position: relative;}
  .sec-data__heading {
    &.text-left {
    	text-align: left !important;
    	@include media('<desktop') {
    		text-align: center !important;
    	}
      span {
        margin-left: 193px;
        @include media('<desktop') {
          margin: 0;
        }
      }
    }
    @include media('<tablet') {
      text-indent: 0;
      text-align: center;
    }
  }
}
.main-content {
  .btn-default {
    margin: 33px auto 0;
    float: none;
    display: block;
  }
  @include media('<tablet') {
    h2 {
      @include font-size(30px, 33px);
      margin-bottom: 25px;
    }
    p {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
}
.plan-sec {
  .container { position: relative;}
}
.celebrate-sec {
  .sec-data { 
    color: $white;
    p { letter-spacing: 0.2px;}
  }
}
.venues {
  .container {
    position: relative;
    z-index: 99;
  }
  .nav-tabs-holder {
    height: 505px;
    display: table;
    width: 100%;
    background: rgba(222,222,222, .8);
    align-content: center;
    @include media('<tablet') {
      height: 400px;
    }
  }
  .nav-tabs {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    margin: 0;
    @include font-size(18px, 22px);
    font-family: 'Oswald', sans-serif;
    @include listreset;
    text-align: center;
    border: none;
    padding-top: 9px;
    letter-spacing: 2px;
    li {
      border: none;
      display: block;
      width: 100%;
      float: none;
      margin-top: 34px;

      @include media('<tablet') {
        margin-top: 10px;
      }
      &:first-child {
        margin-top: 0;
      }
      &.active {
        a {
          background: none;
          color: $gray3; 
          border: none !important;
          &:after {
            background: $brown;
          }
        }
      }
      a {
        display: inline-block;
        vertical-align: top;
        width: auto;
        color: $gray3;
        position: relative;
        padding: 0 0 7px !important;
        border: none !important;
        &:hover {
          background: no-repeat;
          border: none !important;
          &:after {
            background: $brown;
          }
        }
        &:after {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 2px;
          background: transparent;
          content: '';
        }
      }
    }
  }
  &:after {
    background: url('../img/bg-brides-section2.png') no-repeat;
    content: '';
    height: 874px;
    position: absolute;
    right: -22px;
    top: 0;
    width: 1149px;
  }
  .tab-holder {
    margin: 0 -25px;
  }
  .tab-content {
    height: 505px;
    background: rgba(222,222,222, .5);
    text-align: center;
    @include media('<tablet') {
      height: auto;
      padding-top: 30px;
    }
    h2 {
      @include font-size(40px, 43px);
      color: $brown;
      font-family: 'tablet-gothic-compressed',sans-serif;
      font-weight: 200;
      margin: 0 0 26px;
      text-transform: uppercase;
      letter-spacing: 3px;
    }
    p {
      padding: 0 22%;
      color: $gray3;
      @include font-size(14px, 30px);
      margin: 0 0 17px;

      @include media('<desktop') {
        padding: 0 10%;
      }
      span {
        font-family: 'Oswald', sans-serif;
        margin-bottom: 20px;
        display: inline-block;
        vertical-align: top;
      }
    }
    .btn-default {
      margin-top: 15px;
    }
  }
  .tab-inner {
    height: 505px;
    display: table;
    width: 100%;
    align-content: center;
    @include media('<tablet') {
      height: 400px;
    }
    .tab-data {
      display: table-cell;
      vertical-align: middle;
      padding-bottom: 3px;
      @include media('<tablet') {
        padding-bottom: 40px;
      }
      .btn-holder {
        margin-top: 25px;
      }
    }
  }
}
.team-sec {
  position: relative;
  @include media('<desktop') {
    padding-bottom: 20px;
  }
  .sec-data {
    padding: 0 63px 0 44px;
    @include media('<tablet') {
      padding: 0;
    }
  }
  .sec-data__heading {
    padding-left: 42px;
    margin-bottom: 27px;
  }
  .team-sec___image-area {
    margin: -102px -98px -20px -113px;
    position: relative;
    z-index: 999; 

    @include media('<desktop') {
      margin-top: -43px;
    }
    @include media('<tablet') {
      margin: 0 -100px -20px 0;
    }
    img {
      margin: 0 auto;
    }
  }
  .team-svg {
    bottom: auto;
    top: -125px;
    right: auto;

    img {
      float: left;
      width: auto;
    }
  }
}
/*
.sec-data__text {
  &.add { 
    padding-left: 43px;

    @include media('<widescreen') {
      padding-left: 0;
    }
  }
}
*/
.sec-data {
  &.text-center {
    .btn-default {
      display: block;
      margin: 0 auto;
      float: none;
    }
  }
}

.sec-data__image {
  &.pull-right {
    z-index: 9999; 
  }
} 

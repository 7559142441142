/** @define testimonialslider */
.testimonialslider {
  padding: 100px 0 110px;
  position: relative;

  @include media('<desktop') {
    padding: 50px 0 55px;
  }

  @include media('<tablet') {
    padding: 25px 0 36px;
  }
}
/** @define quote-carousel */
.quote-carousel {  
  margin-top: 30px;
  overflow: hidden;
  padding: 0 10px 60px;
  position: relative;
  text-align: center;

  &__text {
    @include font-size(13px, 30px);
    color: $gray3;
    font-family: 'proxima-nova';
    letter-spacing: 1.5px;
    margin: 37px 0 0;
    padding: 0 4%;

    @include media('<desktop') {
      padding: 0;
    } 
  }

  &__title {
    @include font-size(79px, 79px);
    color: #bfaa80;
    display: block;
    font-family: 'tablet-gothic-compressed',sans-serif;
    font-weight: 200;
    letter-spacing: 3px;
    margin: 0 0 20px;
    position: relative;

    @include media('<desktop') {
      @include font-size(40px, 40px);
    }
  }
}

/** @define carousel-indicators */
/* stylelint-disable */
.testimonialslider {
  .carousel-indicators {
    background: none;
    bottom: -10px;
    display: table;
    left: 0;
    margin: 0 auto;
    padding: 0;
    position: absolute;
    right: 0;
    top: auto;

    &__list {
      background: none;
      border: 1px solid #bfaa80;
      cursor: pointer;
      display: inline-block;
      height: 12px;
      width: 12px;
    }
  }
  .carousel-indicators {
    text-align: center;
  }
  .carousel-indicators li.active{
        background: #bfaa80;
  }
  .carousel-indicators li {
      border: 1px solid #bfaa80 !important;
      height: 12px !important;
      width: 12px !important;
      margin: 0 6px !important;
      border-radius: 0 !important;
      padding: 0;
      display: inline-block;
      vertical-align: top;
  }
}
.quote-carousel__title {
  span {
    display: inline-block;
    vertical-align: top;
    padding: 0 35px;
    position: relative;
    z-index: 9;
    }
}

/** @define contact-detail-form */
.contact-detail-form {
  position: relative;
}

/** @define form-area-left */
.form-area-left {
  align-items: center;
  background: $brown;
  color: #fff;
  display: flex;
  margin: 0;
  padding: 10px 45px 18px 22px;
  position: relative;

  @include media('<tablet') {
    display: block;
    padding: 40px 25px 20px;
    text-align: center;
  }

  /* stylelint-disable */
  &::after {
    background: $brown;
    bottom: 0;
    content: '';
    left: 0;
    margin-left: -9999px;
    padding-left: 9999px;
    position: absolute;
    top: 0;
  }
  /* stylelint-enable */

  &__inner {
    width: 100%;
  }
}

/** @define form-area-right */
.form-area-right {
  background: rgba(167, 141, 96, 0.9);
  margin: 0;
  padding: 79px 35px 75px 50px;
  position: relative;
  
  @include media('<tablet') {
    display: block;
    padding: 31px 30px 60px;
    text-align: center;
  }

  /* stylelint-disable */
  &::after {
    background: rgba(167, 141, 96, 0.9);
    bottom: 0;
    content: '';
    margin-right: -9999px;
    padding-right: 9999px;
    position: absolute;
    right: 0;
    top: 0;
  }
  /* stylelint-enable */

}
/** @define contact1 */
.contact1 {
  position: relative;
}

/* stylelint-disable */
.form-area-left h2{
  @include font-size(80px, 81px);
  margin: 0;
  padding: 0;
  font-family: "tablet-gothic-compressed",sans-serif;
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 3.5px;

  @include media('<desktop') {
    @include font-size(40px, 44px);
  }
}
.form-area-left p{
  font-family: 'proxima-nova';
  font-size: 14px;
  line-height: 25px;
  margin:15px 0 0;
  letter-spacing: 0.7px;

  @include media('<desktop') {
    br { display: none;}
  }

  @include media('<tablet') {
    text-align: center;
  }
}
.dispflex {
  display: flex;

  @include media('<tablet') {
    display: block;
  }
}
.contact1 ul{
  position: relative;
  margin: 0;
  max-width: 490px;
  padding:0;
  @include media('<tablet') {
    max-width: 100%;
  }
}
.contact1 ul li{
  list-style-type: none;
  width: calc(50% - 15px);
  float: left;
  margin: 0 0 20px;
  font-family: 'proxima-nova';

  @include media('<phone') {
    width: 100%;
  }
}
.contact1 ul li input{
  @include font-size(14px, 20px);
  height: 34px;
  background: #fff;
  color: $white; 
  width: 100%;
  color: $gray3;
  border: none;
  padding:7px 20px;

  @include media('<tablet') {
    height: 42px;
    padding: 11px 20px;
  }
}
.contact1 ul li:nth-child(even){
  float: right;
}
.contact1 ul li.date {
  background: none;
  position: relative;
  border: 1px solid $white;

  & input {
    background: none;
    color: $white;
    width: calc(100% - 33px);
    height: 32px;
    line-height: 32px;
  }
}
.contact1 ul li.date:after{
  content: '';
  width: 33px;
  height: 32px;
  background: url(../img/date-dd.png) no-repeat;
  position: absolute;
  right: 0;
  top: 0;
}
.contact1 .gform_footer{
  clear: both;
}
.contact1 .gform_footer input[type="submit"]{
  width: 123px;
  height: 34px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 12px;
  background: none;
  text-transform: uppercase;;

  &:hover{
    background: #bfaa80;
  }
}

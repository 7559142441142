
/*  miSlider Core Styles ========================================== */
/* Version: 0.1.14 */

/*  Resets */
 /* stylelint-disable */
.doslider-sec {
	.btn-default {
		width: 144px;
	}
	h2 {
		text-align: center;
		@include font-size(80px, 83px);
	    color: $brown;
	    font-family: 'tablet-gothic-compressed',sans-serif;
	    font-weight: 200;
	    margin: 0 0 37px;
	    letter-spacing: 3px;

	    @include media('<tablet') {
	    	@include font-size(36px, 40px);
	    }
	}
	.mis-stage, .mis-slider, .mis-slide, .mis-container, .mis-container * {
	    -webkit-box-sizing: border-box;
	    -moz-box-sizing: border-box;
	    box-sizing: border-box;
	    margin: 0;
	    padding: 0;
	}
	.blog_slider{ 
		max-width: 1600px;
	  	margin:0 auto;
	}
	.mis-stage, .mis-slider {
	    position: relative;
	}
	.mis-stage {
	    width: 100%; /*  Set stage width as desired */
	    text-align: center; /*  Center list navigation */
	    max-width: 765px;
	    margin: 0 auto;
	}
	.box { 
		background: #fff;
		max-width: 632px;
		margin: 0 auto;
		padding:39px 45px 40px ;
		border: 1px solid #bfaa80;
	}
	.mis-nav-buttons { display: none !important;}
	.mis-slider {
		list-style: none;
		overflow: visible;
		height: 100%;
		margin-top: 0;
	}
	/*  Set slide width and padding */
	.mis-slide {
		width: 100%;
		height: auto;
		padding:0 0 60px;
		display: block;
		position: relative;
		float: left;
		overflow: visible !important;
		opacity:0.3 !important;
	}
	.mis-slide.mis-current { 
		padding:0;
		opacity:1 !important;
	}
	.js .mis-slide {
		display: none;
		opacity: 0;
	}
	.mis-slide.mis-current {
		z-index: 100;
		display: block;
	}
		
	.mis-container {
		display: block;
		width: auto;
		height: auto;
		border: 0;
	}
	/*  Style list navigation */
	.mis-nav-list {
		position: absolute;
		bottom: 4px;
		width: 100%;
		margin: 0 auto; /* center it */
		z-index: 300;
		padding: 0;
	}
	.mis-nav-list li {
		display: inline-block;
		margin: 0 10px;
	}
	.mis-nav-list li a {
		display: block;
		width: 9px;
		height: 9px;
		text-indent: 100%; /* Replace text */
		overflow: hidden;
		white-space: nowrap;
		border: 1px solid #bfaa80;
		border-radius: 7px; 
		background: none;
	}
	.mis-nav-list li.mis-current a, .mis-nav-list li a:hover {
		background: #bfaa80;
	}
	.invisible{ 
		height: 1px; 
		width: 1px !important; 
		display: none !important
	}
	.box-inner { 
		max-width: 425px; 
		margin: 0 auto;
	}
	.box-inner h2 { 
		@include font-size(24px, 26px);
	    color: $brown;
	    font-family: 'tablet-gothic-compressed',sans-serif;
	    font-weight: 700;
	    margin:0 0 9px;
	    letter-spacing: 1px; 
	}
	.box-inner p { 
		@include font-size(14px, 30px);
		font-family: 'proxima-nova',sans-serif;
		color: $gray3;
		margin-bottom: 20px;
		letter-spacing: 0.5px;
	}
	.blog_slider .btn-default {
		padding: 8px !important;
		display: block;
		margin:21px auto 0; 
	}
}
/* stylelint-disable */
form:not(.eventform) .gform_body label{
  display: none !important;
}
.gform_wrapper {
  margin: 0 !important;
}
.gform_wrapper ul{
  position: relative;
  margin: 0;
  max-width: 490px;
  padding:0;

  @include media('<desktop') {
    max-width: 100%;
  }
  @include media('<tablet') {
    max-width: 100%;
  }
}
.gform_wrapper ul li{
  list-style-type: none;
  width: calc(50% - 15px) !important;
  float: left;
  margin: 0 0 20px !important;
  clear: none !important;
  font-family: 'proxima-nova';
  padding:0 !important;

  @include media('<tablet') {
    width: 100% !important;
  }
}
.gform_wrapper ul li input{
  font-size: 14px !important;
  line-height: 20px !important;
  height: 34px;
  background: #fff;
  color: $white; 
  width: 100% !important;
  color: $gray3;
  border: none;
  padding:7px 20px !important;

  @include media('<tablet') {
    height: 42px;
    padding: 11px 20px;
  }
}
.gform_wrapper .ginput_container {
  margin-top: 0 !important;
}
.gform_wrapper ul li:nth-child(even){
  float: right;
}
.gform_wrapper ul li .ginput_container_date {
  background: none;
  position: relative;
  border: 1px solid $white;

  & input {
    background: none;
    color: #777 !important;
    width: calc(100% - 33px);
    height: 32px;
    line-height: 32px;
    position: relative;
    z-index: 1;

    &::placeholder {
      color: #777 !important;
    }
  }
}
#gform_wrapper_3.gform_validation_error #field_3_7 {
  margin-top: 21px !important;
}
.gform_wrapper ul li .ginput_container_date input::-webkit-input-placeholder { /* Chrome */
  color: #fff;
}
.gform_wrapper ul li .ginput_container_date input:-ms-input-placeholder { /* IE 10+ */
  color: #fff;
}
.gform_wrapper ul li .ginput_container_date input::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
  opacity: 1;
}
.gform_wrapper ul li .ginput_container_date input:-moz-placeholder { /* Firefox 4 - 18 */
  color: #fff;
  opacity: 1;
}
.gform_wrapper ul li .ginput_container_date:after{
  content: '';
  width: 33px;
  height: 32px;
  background: url(../img/date-dd.png) no-repeat;
  position: absolute;
  right: 0;
  top: 0;
}
.gform_wrapper .gform_footer{
  clear: both;
  padding-top: 0 !important;
}
.gform_wrapper .gform_footer input[type="submit"]{
  width: 123px !important;
  height: 34px !important;
  border: 1px solid #fff;
  color: #fff;
  font-size: 12px !important;
  background: none;
  text-transform: uppercase;;

  &:hover{
    background: #bfaa80;
  }
}
.gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half){ max-width: 100% !important ;}
.gform_wrapper li.gfield.gfield_error, 
.gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning{ border: none !important; background:none !important;}
.gform_wrapper li.gfield_error input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]), 
.gform_wrapper li.gfield_error textarea{background:rgba(255, 223, 224, 0.25) !important;}
.gform_validation_error #field_4_4,
.gform_validation_error #field_5_4 {
  clear:both !important;
}
@include media('<desktop') {
  .form-area-right {
    padding: 50px 35px 40px;
  }
  .form-area-left {
    text-align: center;
  }
}
#field_5_7 {
  width: 100% !important;
}
#gform_5 .ginput_container_textarea textarea {
  width: 100%;
  height: 68px;
  padding:15px 20px;
  border: none;
}
.eventform ul {
  padding: 0;
  margin: 0;
  max-width: none;
}
.eventform ul li:nth-child(2n) {
  float: right !important;
}
.eventform input[type="text"],
.eventform input[type="tel"],
.eventform input[type="email"],
.eventform select {
  border: 1px solid #bfaa80;
  height: 35px;
}
.eventform textarea {
    border: 1px solid #bfaa80;
}
#field_10_19 {
  display: block;
  clear: both;
  width: 100%;
}
#input_10_14 label,
#input_10_15 label,
#input_10_18 label,
#input_3_10 label,
#input_3_13 label {
  display: inline-block !important;
  color: #000 !important;
  font-family: "proxima-nova" !important;
  font-size: 13px !important;
  letter-spacing: 0 !important;
  margin-top: 3px !important;
  margin-left: 3px;
  font-weight: 300;
}
#field_10_14 li,
#field_10_15 li,
#field_10_18 li,
#field_3_10 li,
#field_3_13 li {
  margin-bottom: 0 !important;
}
#field_10_16 {
  clear: both !important;
  margin-top: -2px !important;
}
#field_10_19,
#field_3_14 {
  clear: both;
  width: 100% !important;
}
.eventform input[type="submit"]{
  background: none;
  border: 1px solid #bfaa80 !important;
  color: #bfaa80 !important;
  font-size: 12px;
  height: 34px;
  text-transform: uppercase;
  width: 123px;
  display: block !important;
  margin: 0 auto !important;
}
.eventform input[type="submit"]:hover {
    background: #bfaa80 !important;
    color: #fff !important;
}

/* #field_10_14 label.gfield_label,
#field_10_15 label.gfield_label,
#field_10_16 label.gfield_label,
#field_10_17 label.gfield_label,
#field_10_18 label.gfield_label,
#field_3_10 label.gfield_label,
#field_3_11 label.gfield_label,
#field_3_12 label.gfield_label,
#field_3_13 label.gfield_label,
#field_3_14 label.gfield_label {
  display: block !important;
  color: #bfaa80;
  font-family: "tablet-gothic-compressed",sans-serif;
  font-size: 22px;
  font-weight: 100;
  letter-spacing: 2px;
} */
.eventform .datepicker,
#field_3_7 .datepicker {
  color: #777 !important;
}
.gform_wrapper ul li .ginput_container_date input::-moz-placeholder {
  color: #777 !important;
}
#label_9_7_1{
    display: inline-block !important;
    font-weight: normal !important;
    margin: 5px 0 0 3px !important;
  }
#gform_fields_9 select {
  padding: 7px 20px !important;
}
#input_3_10 li {
  max-width: 33.33%;
}
#field_3_7 {
  clear: both !important;
  width: 100% !important;
}
#input_3_14 {
  max-height: 120px;
}

ul#input_10_15:after {
    content: '';
    background: #bfaa80;
    height: 1px;
    right: 85px;
    left: 0;
    top: 41px;
    position: absolute;

    @media (max-width: 992px) {
      right: 50px;
    }

    @media (max-width: 767px) {
      display: none;
    }
}
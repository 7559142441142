// custom variables and override bootstrap variables
$gray: #333;
$gray2: #eee;
$gray3: #777;
$gray4: #e4e4e4;
$brown: #bfaa80;
$yellow: #fa0;
$black: #000;
$white: #fff;
$offwhite: #fafafa;
$dark-green: #008245;
$light-blue: #6299d7;
$blue: #000948;
$green: #048448;

/** @define navarea */ 
.navarea {
  left: 0;
  position: absolute;
  right: 0;
  top: 50px;

  &__holder {
    display: block;
    position: relative;
    z-index: 999;
    
    /* stylelint-disable */
      &::before {
      background: $gray4;
      top: -1px;
      content: '';
      left: 0;
      right: 0;
      margin: 0 -9999px;
      padding: 0 9999px;
      position: absolute;
      height: 1px;
    }
  
    /* stylelint-enable */


    &--inner {
      background: $offwhite;
      display: flex;
      position: relative;
    }
  }
  
  /* stylelint-disable */
  &::after {
    background: $offwhite;
    bottom: 0;
    content: '';
    left: 0;
    margin-right: -9999px;
    padding-right: 9999px;
    position: absolute;
    top: 0;
  }
  /* stylelint-enable */

}

/** @define navdescription */ 

.navdescription {
  align-items: center;
  display: flex;
  padding: 66px 10% 0 14%;
  position: relative;
  text-align: center;
  width: 72%;
  
  @include media('<widescreen') {
    padding: 30px 5% 0;
  }

  @include media('<tablet') {
    display: none;
  }

  &__quote {
    @include font-size(24px, 44px);
    border: 0;
    color: #bfbfbf;
    display: block;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    letter-spacing: 2.2px;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;

    /* stylelint-disable */
    &::after {
      color: $brown;
      background: url('../img/bg-quote.png') no-repeat;
      content: '';
      left: 50%;
      width: 93px;
      height: 82px;
      margin-left: -46px;
      position: absolute;
      top: -105px;

      @include media('<widescreen') {
        width: 50px;
        height: 39px;
        background-size:100% 100%;
        top: -40px; 
      }
    }
    /* stylelint-enable */

    &--cite {
      @include font-size(16px, 20px);
      color: $brown;
      display: block;
      font-style: normal;
      letter-spacing: 0.5px;
      margin-top: 30px;
    } 
  }
}

/** @define navlist */ 

.navlist {
  @include font-size(18px, 22px);
  @include listreset;
  background: $gray2;
  float: left;
  font-family: 'Oswald', sans-serif;
  letter-spacing: 1.7px;
  padding-bottom: 90px;
  padding-top: 46px;
  position: relative;
  width: 27.2%;

  @include media('<extrawidescreen') {
    padding-left: 20px;
    width: 19.2%;
    
  }
  
  @include media('<tablet') {
    box-sizing: border-box;
    padding: 20px 0 20px 25px;
    width: 100%;
  }

  /* stylelint-disable */
  &::after {
    background: $gray2;
    bottom: 0;
    content: '';
    left: 0;
    margin-left: -9999px;
    padding-left: 9999px;
    position: absolute;
    top: 0;
  }
  @include media('<tablet') {
    &::before {
      background: $gray2;
      bottom: 0;
      content: '';
      right: 0;
      margin-right: -9999px;
      padding-right: 9999px;
      position: absolute;
      top: 0;
    }
  }
  /* stylelint-enable */

  &__listitem {
    margin-top: 42px;
    padding: 0 10px 0 10%;

    @include media('<extrawidescreen') {
      margin-top: 25px;
      padding-left: 0;
    }

    &--link { 
      border-bottom: 2px solid transparent;
      color: $gray3;
      display: inline-block;
      padding-bottom: 4px;
      vertical-align: top;
      
      /* stylelint-disable */
      &:hover {
        border-color: $brown;
        color: $gray3;
        text-decoration: none;
      }
      /* stylelint-enable */
    }
  }
}

/** @define subnav */ 

.subnav {
  @include font-size(18px, 22px);
  @include listreset;
  background: $offwhite;
  border-left: 1px solid $gray4;
  bottom: 0;
  left: 100%;
  padding: 30px 0;
  position: absolute;
  top: 0;
  width: 380px;
  z-index: 999;

  @include media('<extrawidescreen') {
    @include font-size(15px, 20px);
    width: 250px;
  }
  
  @include media('<desktop') {
    @include font-size(15px, 20px);
    width: 200px;
  }

  @include media('<tablet') {
    background: none;
    border: 0;
    left: 0;
    padding: 0;
    position: relative;
    right: 0;
    top: auto;
    width: 100%;
  }

  &__item {
    margin-top: 30px;
    padding: 0 10px 0 114px;
    
    @include media('<extrawidescreen') {
      margin-top: 15px;
      padding-left: 25px;
    }

    @include media('<desktop') {
      margin-top: 15px;
      padding-left: 15px;
    }

    &--subnavlink {
      border-bottom: 2px solid transparent;
      color: $gray3;
      display: inline-block;
      padding-bottom: 4px;
      vertical-align: top;
    }
  }

  /* stylelint-disable */
  &::after {
    background: $white;
    bottom: 0;
    content: '';
    display: none;
    left: 100%;
    margin-right: 9999px;
    padding-right: 9999px;
    position: absolute;
    top: 0;
    z-index: 9;

    @include media('<tablet') {
      display: none !important;
    }
  } 
  /* stylelint-enable */ 
}

/** @define subdropdown */

.subdropdown {
  align-items: center;
  border-left: 1px solid $gray4;
  bottom: 0;
  box-sizing: border-box;
  color: $gray3;
  display: flex;
  left: 100%;
  padding: 0 0 0 20%;
  position: absolute;
  top: 0;
  width: 566px;
  z-index: 999;
  
  @include media('<extrawidescreen') {
    padding-left: 15px;
    width: 627px;
  }

  @include media('<widescreen') {
    padding-left: 15px;
    width: 501px;
  }
  @include media('<desktop') {
    padding-left: 15px;
    width: 402px;
  }

  @include media('<tablet') {
    background: none;
    border: 0;
    left: 0;
    padding: 0;
    position: relative;
    right: 0;
    top: auto;
    width: 100%;
  }

  &__holder2 {
    padding-bottom: 25px;
    padding-left: 20%;
    width: 100%;

    @include media('<tablet') {
      padding-left: 0;
    }

    &--heading {
      @include font-size(40px, 43px);
      color: $brown;
      font-family: 'tablet-gothic-compressed',sans-serif;
      font-weight: 200;
      margin-bottom: 30px;
      text-transform: uppercase;

      @include media('<desktop') {
        @include font-size(30px, 33px);
      }

      @include media('<tablet') {
        margin-bottom: 10px;
      }
    }
  }
}

/** @define navbtn */ 

.navbtn {
  margin-top: 30px;

  &__btn {
    clear: both;
    margin-top: 15px;
  }
}

/** @define navbottom */

.navbottom {
  background: $gray4;
  padding: 28px 0;
  position: relative;
  z-index: 999;

  &__search {
    float: left;
    margin-left: 9px;

    &--ico {
      background: url('../img/bg-search-placeholder.png') no-repeat;
      border: 0;
      display: block;
      font-size: 0;
      height: 40px;
      line-height: 0;
      width: 356px;

      @include media('<extrawidescreen') {
        width: 340px;
      }

      @include media('<widescreen') {
        background: url('../img/bg-search-placeholder2.png') no-repeat;
        width: 135px;    
      }

    } 
  } 

  /* stylelint-disable */
  &::after {
    background: $gray4;
    bottom: 0;
    content: '';
    left: 0;
    margin: 0 -9999px;
    padding: 0 9999px;
    position: absolute;
    top: 0;
    z-index: -1;
  }

  
  /* stylelint-enable */ 
} 

/** @define navsearchbox */

.navsearchbox {
  background: $gray2;
  bottom: -99px;
  left: 0;
  padding: 234px 0 150px;
  position: absolute;
  right: 0;
  @include media('<extrawidescreen') {
    padding-top: 113px;
  }
  @include media('<desktop') {
    padding-top: 133px;
  }
  
  @include media('<tablet') {
    padding-bottom: 50px;
    padding-top: 234px; 
  }

  /* stylelint-disable */
  &::after {
    background: $gray2;
    bottom: 0;
    content: '';
    left: 0;
    margin: 0 -9999px;
    padding: 0 9999px;
    position: absolute;
    top: 0;
    z-index: -1;
  }
  /* stylelint-enable */

}

/** @define naveform */

.naveform {
  position: relative;

  &__textfield {
    @include font-size(80px, 91px);
    background: none;
    border: 0;
    box-sizing: border-box;
    color: $brown;
    font-family: 'Oswald', sans-serif;
    font-weight: 100;
    height: 111px;
    padding: 0 15px;
    text-align: center;
    width: 100%;

    @include media('<tablet') {
      @include font-size(32px, 40px);
    }
  }

  &__submit {
    @include font-size(12px, 33px);
    background: none;
    border: 1px solid $brown;
    color: $brown;
    display: block;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    height: 34px;
    margin: 146px auto 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    width: 163px;

    @include media('<tablet') {
      margin-top: 20px;
    }
  }

  &__searchclose {
    box-shadow: none;
    display: block;
    float: none;
    margin: 15px auto 0;
  }
}

/** @define socialicon */

.socialicon {
  @include listreset;
  float: right;
  padding-right: 14px;
  padding-top: 6px;
  
  @include media('<extrawidescreen') {
    padding-right: 0;
  }

  &__iconlist {
    background: $brown;
    float: left;
    height: 28px;
    margin-left: 13px;
    width: 29px;
  
    @include media('<desktop') {
      margin-left: 6px;
    }

    /* stylelint-disable */
    &:hover {
      background: $white;
      a {
        color: $brown;
        &:hover { text-decoration: none;}
      }
    }
    /* stylelint-enable */

    &--icon {
      @include font-size(18px, 18px);
      color: $white;
      display: block;
      padding: 5px 0 0;
      text-align: center;
    }
  }
}

/** @define navlinks */

.navlinks {
  @include listreset;
  @include font-size(14px, 18px);
  font-family: 'Oswald', sans-serif;
  overflow: hidden;
  padding-left: 6px;
  padding-top: 13px;
  position: relative;
  
  @include media('<desktop') {
    @include font-size(10px, 16px);
  }

  @include media('<tablet') {
    display: none;
  }

  &__listlinks {
    display: inline-block;
    padding: 0 25px;
    position: relative;
    vertical-align: top;

    @include media('<widescreen') {
      padding: 0 10px;
    }

    @include media('<desktop') {
      padding: 0 6px 0 8px;
    }

    /* stylelint-disable */
    &::after {
      background: $brown;
      bottom: 7px;
      content: '';
      left: 0;
      width: 2px;
      position: absolute;
      top: 3px;
    }
    &:first-child:after { display: none;}

    &:hover {
      a {
        text-decoration: none;
        border-color: $brown;
        color: $brown;
      }
    }
    /* stylelint-enable */

    &--links {
      border-bottom: 2px solid transparent;
      color: $gray3;
      display: inline-block;
      letter-spacing: 1.5px;
      padding-bottom: 4px;
      vertical-align: top;
    }
  }
}

/** @define homenav2 */

.homenav2 {
  bottom: 0;
  font-family: 'Oswald', sans-serif;
  left: 0;
  position: absolute;
  right: 0;
  @include media('<desktop') {
    display: none;
  }

}

/** @define homenavelist */
.homenavelist {
  @include listreset;
  @include font-size(14px, 20px);
  background: $white;
  letter-spacing: 0.5px;
  overflow: hidden;
  padding: 24px 75px 23px;
  position: relative;

  @include media('<widescreen') {
    padding: 14px 75px 12px;
  }

  @include media('<tablet') {
    padding: 10px 25px;
    text-align: center;
  }

  &__list {
    float: left;
    padding: 0 22px;
    position: relative;

    @include media('<tablet') {
      display: inline-block;
      float: none;
      vertical-align: top;
    }

    /* stylelint-disable */
    &::first-child {
      padding-left: 0;
    }
    &::after {
      background: $brown;
      bottom: 7px;
      content: '';
      left: 0;
      width: 2px;
      position: absolute;
      top: 6px;
    }
    &:first-child:after { display: none;}

    &:hover {
      a {
        text-decoration: none;
        border-color: $brown;
        color: $brown;
      }
    }
    /* stylelint-enable */
  
    &--links2 {
      border-bottom: 2px solid transparent;
      color: $gray3;
      display: inline-block;
      padding-bottom: 4px;
      vertical-align: top;
    }
  }
}


/* stylelint-disable */

.navbar-toggle { 
  display: block;
  margin: 4px 0 0;
  padding: 0;
  border: none;
  -webkit-transition:all 0.2s linear;
  transition:all 0.2s linear;
  position: relative;
  width: 20px;
  height: 24px;
  position: absolute;
  top: -49px;
  right: 0; 
  .icon-bar {
    position: absolute;
    left: 0;
    right: 0;
    top:7px; 
    height: 2px;
    border-radius: 1px;
    background: $brown;
    margin-bottom: 7px;
    -moz-transform:rotate(45deg);
    -webkit-transform:rotate(45deg);
    -ms-transform:rotate(45deg);
    transform:rotate(45deg);
    left:8%;
    right:8%;
    transition:all 0.2s linear;
    &.last {
      margin-bottom: 0;
      -moz-transform:rotate(-45deg);
      -webkit-transform:rotate(-45deg);
      -ms-transform:rotate(-45deg);
      transform:rotate(-45deg);
      top: 3px;
      left:8%;
      right:8%;
      transition:all 0.2s linear;
    }
    &.add { 
      opacity: 0;
      top:3px; 
    }
  }
  &.collapsed {
    .icon-bar {
      width: 21px;
      opacity: 1;
      transform:none;
      left: 0;
      top: 0;
      &.last {
        top:10px; 
      }
      &.add {
        top:3px; 
      }
    }
    
  }
}
.navbottom__search {
  position: static !important;
  .dropdown-menu {
    right: 0;
    box-shadow: none !important;
    padding: 0 !important;
    border-radius: 0;
  }  
}
p:empty {
  display: none;
}
.ssi-facebook {
  margin-left: 0;
}
#menu-item-235 {padding-left: 0;}
.navlinks {
  .homenavelist__list {
    padding: 0 24px;

    @include media('<extrawidescreen') {
      padding: 0 8px;
    }
    &::first-child { padding-left: 22px;}
    &:after {
      bottom: 6px;
      top: 3px;
    }
  }
}

.mobiel-venues {
  h2 {
    @include font-size(40px, 44px);
  }
}
.venues-list {
  border-bottom: 1px solid $brown;
  margin-bottom: 40px;
  ul {
    @include listreset;

    .open-menu2 {
      &:hover {
        color: $brown;
      }
    }
  }
  .btn-default {
    float: none;
    display: block;
    margin: 15px auto 0;
  }
  .subdropdown {
    overflow: hidden;
    padding: 0 20px 30px;
    text-align: center;
  }
}
@include media('<desktop') {
  
  .socialicon .alignleft li {
    margin-left: 6px;
  }
}

.navarea {
  .collapse {
    &.in {
      border-top: 1px solid #e4e4e4;
    }
  }
}

.open-close {
  &.active {
    .subnav {
      &::after { display: block;}
    }
  }
}

.naveform__textfield::placeholder {
    color: #bfaa80;
    font-family: "Oswald",sans-serif;
    font-size: 80px;
    font-weight: 100;
    height: 111px;
    line-height: 91px;
}

.navbtn__btn {
  color:$brown !important;

  &:hover {
    color: #fff !important;
  }
}

.subnav__item--subnavlink:hover { text-decoration: none;}
.open-close {
  &.active {
    .navlist__listitem--link {
      border-color: #bfaa80;
      color: #777;
    }
  }
  .slide {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    @include media('<tablet') {
      position: static;
    }
  }
}
.open-close2 {
  &.active {
    .opener2 {
      border-color: #bfaa80 !important;
      color: #777 !important;
    }
  }
  .slide2 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    @include media('<tablet') {
      position: static;
    }
  }
}
.navdescription {
  .fade-slider2__control-box {
    left: 0;
    right: 0;
    width: 100%;
    bottom: -50px;
    text-align: center;
    z-index: 1;
    .carousel-indicators {
      width: 100%;
      left: 0;
      right: 0;
      z-index: 1;
      li {
        float: none;
        display: inline-block;
        vertical-align: top;
        margin: 0 5px;
        &.active {
          background: $brown;
        }
      }
    }
  }
  .fade-slider2 {
    .item,
    .active.left,
    .active.right {
        opacity: 0;
    }
    .active,
    .next.left,
    .prev.right {
        opacity: 1;
    }
  }
}




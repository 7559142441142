/** @define positioning-sec2 */
.positioning-sec2 {
  padding: 79px 0 100px;
  position: relative;

  @include media('<widescreen') {
    padding: 40px 0 50px;
  }

  @include media('<tablet') {
    padding: 40px 0 20px;
  }
}

/** @define features-tab */
.features-tab {
  position: relative;
} 

/** @define meet-sec */
.meet-sec {
  position: relative;

  &__image {
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 1;

    @include media('<widescreen') {
      bottom: -120px;
    }

    @include media('<tablet') {
      position: static;
    }
  }
}



 /* stylelint-disable */ 
.features-tab {
  .sec-data {
    padding-left: 12%;
    padding-top: 40px;
    padding-right: 15px;
    .sec-data__heading {
      margin-bottom: 32px;

      @include media('<tablet') {
        margin-bottom: 20px;
      }
    }
    @include media('<tablet') {
      padding-left: 15px;
      padding-top: 15px;
    }
  }
  .flex-align {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .tab-image {
    position: relative;
    z-index: 99;
    img {
      float: right;
      @include media('<extrawidescreen') {
        float: none;
        width: 100%;
        height: auto;
      }
    }
  }
  .nav-tabs-holder {
    position: relative;
    max-width: 813px;
    margin: 0 auto -75px;
    z-index: 999;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    height: 150px;
    box-shadow: 0 6px 20px 0 rgba(0,0,0, .2);
    @include media('<extrawidescreen') {
      margin-bottom: -20px;
    }
    @include media('<tablet') {
      height: 68px;
      margin-bottom: 0;
    }

    .tab-content {
      @include media('<tablet') {
        margin-top: -20px;
      }
    }
  }
  .nav-tabs {
    position: relative;
    width: 100%;
    @include font-size(16px, 20px);
    border: 0;
    font-family: 'Oswald', sans-serif;
    @include media('<tablet') {
      @include font-size(12px, 20px);
    }
    li {
      border: none;
      padding: 0 32px;
      position: relative;
      float: none;
      display: inline-block;
      vertical-align: top;

      @include media('<tablet') {
        padding: 0 12px;
        margin-bottom: 12px;
      }
      &:after {
        position: absolute;
        left: 0;
        top: 3px;
        bottom: 7px;
        width: 2px;
        content: '';
        background: $brown;
      } 
      &:first-child {
        &:after { display: none;}
      }
      a {
        padding: 0 0 5px !important;
        border: 0 !important;
        display: inline-block;
        color: $gray3 !important;
        background: none !important;
        vertical-align: top;
        border-bottom: 2px solid transparent !important;
        &:hover { 
          background: none;
          color: $gray3 !important;
          border-bottom: 2px solid $brown !important;
        } 
      }
      &.active {
        color: $gray3 !important;
        a {
          padding: 0 0 5px !important;
          border: 0 !important;
          &:hover { background: none;}
          border-bottom: 2px solid $brown !important;
        }
      }
    }
  }
}
.meet-sec {
  position: relative;
  .sec-data {
    padding: 112px 0 88px;
    @include media('<widescreen') {
      padding: 40px 0;
    }
  }
}
.images-block {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
.fullblock_image {
  margin-bottom: 75px;
  @include media('<tablet') {
    margin-bottom: 15px;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.page-template-cuisine {
  .images-block {
    &.mt100 { margin: 0 !important;}
  }
}




/** @define blog-area */
.blog-area {
  padding: 90px 0;
  position: relative;

  @include media('<tablet') {
    padding-top: 30px;
  }
}

/** @define blogpost-area */
.blogpost-area {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 100%;
}

/** @define blogpost */
.blogpost {
  border: 1px solid $brown;
  display: inline-block;
  margin: 0 15px 28px;
  position: relative;
  text-align: center;
  vertical-align: top;
  width: calc(33.3% - 30px);

  @include media('<tablet') {
    width: calc(49.5% - 30px);
  }

  @include media('<phone') {
    margin: 0 0 20px;
    width: 100%;
  }

  &__image {
    position: relative;
  }

  &__meta {
    @include font-size(12px, 15px);
    color: $gray3;
    display: block;
    font-family: 'Oswald', sans-serif;
    margin-top: 30px;
  }

  &__heading {
    @include font-size(26px, 30px);
    color: $brown;
    font-family: 'tablet-gothic-compressed',sans-serif;
    font-weight: 200;
    letter-spacing: 3px;
    margin-top: 15px;
  }

  &__text {
    @include font-size(9px, 19px);
    color: $gray3;
    margin: 20px 0 0;
  }
}

/** @define blogpost-brown */
.blogpost-brown {
  background: $brown;
  color: $white;
}













 /* stylelint-disable */
 .blogpost-brown {
  .blogpost__meta {
    margin-top: 0;
    color: $white;
  }
  .blogpost__heading { color: $white;}
 }
 .js-masonry { 
    margin: 0 -15px;
    @include media('<phone') {
      margin: 0 ;
    }
  }
 .blogpost {
  a {
    display: block;
    padding: 19px 10px 26px;
    &:hover {
      text-decoration: none;
      opacity: 0.55;
      img { opacity: 0.75;}
    }
  }
  img {
    margin: 0 auto;
  }
 }
 .blogpost-area {
  .btn-area {
    margin-top: 12px;
    .btn-default {
      margin: 0 auto;
      display: block;
      float: none;
    }
  }
 } 

/** @define navinfo */
.navinfo {   

  animation: slide 0.5s forwards;
  animation-delay: 0s;
  bottom: 0;
  padding: 62px 0 65px;
  position: absolute;
  right: -472px;
  top: 0;
  width: 472px;
  z-index: -10;

  @include media('<desktop') {
    bottom: auto;
    left: 0;
    right: 0;
    width: 100%;
  }

  &__holder {
    align-items: center;
    background: $white;
    box-sizing: border-box;
    color: $gray3;
    display: flex;
    height: 100%;
    position: relative;
  } 
}
/* stylelint-disable */
.home-crousel {
  .carousel-indicators {
    li {
      cursor: default;

      &.active {
        width: auto !important;
        height: auto !important;
        
      }

      a {
        color: $brown;
        text-decoration: none !important;
      }
    }
  }
}
@include media('<desktop') {
  .navinfo { display: none !important;}
}
/* stylelint-enable */

/** @define navinfocontent */
.navinfocontent {
  box-sizing: border-box;
  padding: 10px 14.5% 22px;
  width: 100%;
  
  &__heading {
    @include font-size(40px, 44px);
    color: $brown;
    font-family: 'tablet-gothic-compressed',sans-serif;
    font-weight: 200;
    letter-spacing: 2px;
    margin: 0;
    padding: 0 0 10px;
    position: relative;

    /* stylelint-disable */
    &:after {
      position: absolute;
      left: 0;
      bottom: -5px;
      content: "";
      background: $brown;
      width: 55px;
      height: 2px;
    }
    /* stylelint-enable */
  }

  &__text {
    @include font-size(14px, 30px);
    color: $gray3;
    font-family: 'proxima-nova',sans-serif;
    letter-spacing: 0.7px;
    margin-top: 21px;
  }

  &__btn {
    color: $brown;
    letter-spacing: 1px;
    margin-top: 30px;   
  }
}


/* stylelint-disable */
.carousel__control-box {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 151px;
    @include media('<desktop') {
      position: relative;
      display: block;
      width: 100%;
    }
}
.navlist {
  li {
    a {
      &:hover {
        border-color: $brown;
        color: $gray3;
        text-decoration: none;
      }
    }
  }
  a:hover,
  a:visted,
  a:focus {
    text-decoration: none !important;
    border: 0;
  }
}
a:visted,
a:focus {
  text-decoration: none !important;
  border: 0;
}
.home-crousel {
  .carousel-indicators {
    position: relative;
      left: auto;
      right: auto;
      top: 6px;
      bottom: auto;
      margin: 0 0 9px;
      width: 100%;
      background: $gray2;
      z-index: 99;
      padding: 14px 0 0;
      font-family: 'Oswald', sans-serif;
      @include font-size(14px, 18px);
      @include media('<desktop') {
        padding: 0;
      }
      li {
          background: #eee;
          width: 100%;
          height: auto;
          border-radius: 0;
          text-indent: 0;
          display: block;
          margin: 0;
          cursor: pointer;
          padding: 31px 0 0 41px;
          text-align: left;
          position: static;
          border:none;
          color: $gray3;
          transition: all 0.4s ease;

          @include media('<desktop') {
            float: left;
            padding: 0 15px;
            width: auto;
          }
          @include media('<tablet') {
            margin-top: 15px;
          } 
          a {
              color: $gray3;
              position: relative;
              display: inline-block;
              vertical-align: top;
              &:after {
                  position: absolute;
                  left: 0;
                  bottom: -5px;
                  content: "";
                  background: transparent;
                  width: 27px;
                  height: 2px;
              }
              &:hover {
                text-decoration: none;
              }
          }
          &.active {
            width: 100%;
            height: auto;
            margin: 0;
            @include media('<desktop') {
              float: left;
              padding: 0 15px;
              width: auto;
            }
            @include media('<tablet') {
              margin-top: 15px;
            }
          }
          &.active,
          &:hover {
              a {
                &:after { 
                  background: $brown;
                }
              }
              .naveinfo {
                  display: block !important;
                  right: 100%;
              } 
          }
          &:last-child {
              padding-left: 0;
              @include media('<desktop') {
                display: inline-block;
                vertical-align: top;
                width: 100%;
                float: none;
                text-align: center;
                text-transform: uppercase;
                letter-spacing: 2px;
              }
              a {
                  display: block;
                  padding: 25px 15px 25px 41px;
                  background: $brown;
                  color: $white;
                  @include media('<desktop') {
                    padding: 15px 15px 15px 15px;
                  }
                  &:after { display: none;}
                  &:hover {
                    background: $gray3 !important;
                    color: $white !important;
                  }
              }
              .btn-default {
                padding: 8px;
                background: none;
                color: $brown;

                &:hover { 
                  background: $brown !important;
                  color: $white !important;
                }
              }
          }
      }
  }
}
.bg-stretch {
    background: #333;
    background-size: cover;
    background-position: 50% 50%;
    color: #fff;
    height: 83vh;
    position: relative;
    top:75px;
    bottom: 0;
    @include media('<desktop') {
      height: 65vh;
    } 
}
.navbottom {
  .container { width: 100%; max-width: none;}
}
.home-crousel {
  .carousel-fade {
    position: relative;
    padding:0 75px;

    @include media('<widescreen') {
      padding: 0 15px;
    }
    @include media('<tablet') {
      min-height: inherit !important;
      padding: 0 15px 10px;
    }
      .carousel-inner {
        height: 100vh;
        @include media('<desktop') {
          height: auto;
        }
          .item {
              transition-property: opacity;
              height: 92vh;
              @include media('<desktop') {
                height: auto;
              }
              img {
                width: 100%;
                height: auto;
              }
              .mobi { 
                display: none;
                @include media('<desktop') {
                  display: block;
                }
              }
              .bg-stretch {
                @include media('<desktop') {
                  display: none !important;
                }
              }
          }
          .item,
          .active.left,
          .active.right {
              opacity: 0;
          }
          .active,
          .next.left,
          .prev.right {
              opacity: 1;
          }
          .next,
          .prev,
          .active.left,
          .active.right {
              left: 0;
              transform: translate3d(0, 0, 0);
          }
      }
      .carousel-control {
          z-index: 2;
      }
  }
}


@media screen and ( max-height: 800px ){
    .win-min-height {
      min-height: 800px !important;
    }
    .home-crousel .carousel-fade .carousel-inner,
    .home-crousel .carousel-fade .carousel-inner .item,
    .bg-stretch {
      height: 800px;
    }
}

@include media('<desktop') {
  .win-min-height {
    min-height: inherit !important;
  }
  .home-crousel .carousel-fade .carousel-inner,
  .home-crousel .carousel-fade .carousel-inner .item,
  .bg-stretch {
    height: auto !important;
  }
  .home-crousel {
    .carousel__control-box { display: none !important;}
    .carousel-inner {
      .item {
        display: block !important;
        opacity: 1 !important;
        margin-bottom: 16px;
      }
    }
  }
}

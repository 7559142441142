/** @define footer */
.footer {
  background: url('../img/bg-footer.jpg') no-repeat #555;
  background-size: cover;
  color: #bbb;
  font-family: 'Oswald', sans-serif;
  margin: 0 auto;
  padding: 53px 0 83px;
  position: relative;

  @include media('<desktop') {
    background-image: none;
    display: block;
    padding: 40px 0;
  }

  &__footermain {
    margin: 0 0 19px 46px;
    width: 100%;

    @include media('<desktop') {
      display: block;
      margin-left: 0;
    }
  }
}

/** @define footerlogo */
.footerlogo {
  margin-bottom: 40px;
  text-align: center;
}

/** @define footerlist */
.footerlist {
  @include font-size(14px, 30px);
  float: left;
  padding: 0 29px;
  position: relative;
  width: 18.8%;
  z-index: 2;
  
  @include media('<desktop') {
    padding: 0 15px;
  }

  @include media('<desktop') {
    float: none;
    line-height: 18px;
    padding-bottom: 28px;
    width: 100%;
  }

  &::after {
    background: $brown;
    bottom: 11px;
    content: '';
    position: absolute;
    right: 0;
    top: 8px;
    width: 1px;

    @include media('<desktop') {
      display: none;
    } 
  }

  &__item {
    float: none;
    list-style-type: none;

    @include media('<desktop') {
      display: block;
      line-height: 38px;
      margin-top: 0;

    }

    &:hover {
      color: $brown;
      text-decoration: none;
    }

    &--link {
      color: #bbb;

      &:hover {
        color: $brown;
        text-decoration: none;
      }
    }

    &--span {
      color: #bfaa80;
    }

    &--p {
      line-height: 30px;
      margin: 0;
    }
  }

  &:first-child {
    padding-left: 8%;
    width: 25.5%;

    @include media('<desktop') {
      padding: 0 15px;
    }

  }

  &:last-child {
    width: 15%;
    &::after { display: none;}

  }
}

/** @define footer-bottom */
.footer-bottom {
  @include font-size(14px, 18px);
  clear: both;
  display: block;
  letter-spacing: 0.5px;
  
  @include media('<desktop') { 
    line-height: 27px;
  }

  &__links {
    color: #bbb;
  }
}

/* stylelint-disable */
.footer-bottom__links:hover {
  color: #fff;
}
.footer-bottom {
  i {
    font-style: normal;
    color: $brown;
  }
  p { margin: 0;}
}
.footer__footermain {
  .collapse { display: block;}
  @include media('<desktop') {
    border-bottom: 1px solid $brown;
    .collapse { display: none;}
    .collapse.in { display: block;}
    .open-menu2 { 
      display: block;
    }
  }

  a:hover, a:focus {
    text-decoration: none;
  }
}
.open-menu2:hover {
  color: $white;
  text-decoration: none;
}
@include media('<desktop') {
  .footerlist {  
    width: 100% !important;
  }
}

.open-menu2 {
    display: none;
    color: $brown;
    @include font-size(20px, 51px);
    font-family: 'tablet-gothic-compressed',sans-serif;
    font-weight: 200;
    height: 53px;
    border-top: 1px solid $brown;
    padding: 0 18px;
    position: relative;
    letter-spacing: 2px;
    text-transform: uppercase;

    a:hover, a:focus { color: $brown; }
    
    i {
      position: absolute;
      display: block;
      right: 18px;
      top: 50%;
      width: 17px;
      height: 17px;
      margin-top: -2px;
      transition:all 0.2s linear;

      &:before {
        position: absolute;
        width: 17px;
        height: 1px;
        content: '';
        background: $brown;
        left: 0;
        transition:all 0.2s linear;
        transform:rotate(45deg);
      }

      &:after {
        position: absolute;
        width: 17px;
        height: 1px;
        content: '';
        background: $brown;
        left: 0;
        transition: all 0.2s linear;
        transform: rotate(-45deg);
      } 
    }
    &.collapsed {
      i {
        &:before {
          transform: rotate(90deg);
        }

        &:after {
          transform: none;
        }
      }
    }
  }


  #footer-navigation1 {
    width: 20.6%;
  }



/** @define careers */
.careers {
  padding: 70px 0 0;
  position: relative;

  @include media('<desktop') {
    padding: 30px 0 0;
  }

  &__holder {
    font-family: 'proxima-nova',sans-serif;
    position: relative;

    &--heading {
      @include font-size(79px, 83px);
      color: $brown;
      font-family: 'tablet-gothic-compressed',sans-serif;
      font-weight: 200;
      letter-spacing: 4px;
      margin: 0;
      position: relative;
      text-align: left;

      @include media('<desktop') {
        @include font-size(40px, 43px);
      }

      @include media('<tablet') {
        @include font-size(22px, 26px);
      }
    }
  }
}

/** @define openpos */
.openpos {
  padding: 40px 0 0;
  position: relative;

  @include media('<desktop') {
    padding: 30px 0 0;
  }

  &__holder2 {
    font-family: 'proxima-nova',sans-serif;
    position: relative;

    &--heading2 {
      @include font-size(79px, 83px);
      color: $brown;
      font-family: 'tablet-gothic-compressed',sans-serif;
      font-weight: 200;
      letter-spacing: 4px;
      margin: 0;
      position: relative;
      text-align: center;

      @include media('<desktop') {
        @include font-size(40px, 43px);
      }

      @include media('<tablet') {
        @include font-size(30px, 36px);
      }
    }
  }
}

/** @define position */
.position {
  display: block;
  margin: 0 0 55px;

  @include media('<tablet') {
    margin-bottom: 25px;
  }

  &__title {
    @include font-size(40px, 42px);
    color: $brown;
    font-family: 'tablet-gothic-compressed',sans-serif;
    font-weight: 200;

    @include media('<tablet') {
      @include font-size(30px, 33px);
    }
  }

  &__location {
    @include font-size(18px, 30px);
    color: $gray3;
    font-family: 'Oswald';
    font-weight: 400;
  }

  &__detail {
    @include font-size(14px, 30px);
    color: $gray3;
    font-family: 'proxima-nova';
    font-weight: 400;
    margin: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

/** @define searchholder */
.searchholder {
  border: 1px solid #bfaa80;
  height: 38px;
  margin-bottom: 40px;
  margin-top: 40px;
  overflow: hidden;
  padding: 0;
  position: relative;
}


/* stylelint-disable */
.openpos__holder2--heading2 {
  margin-bottom: 40px;
  span {
    display: inline-block;
    vertical-align: top;
    padding: 0 35px;
    position: relative;
    z-index: 9;

    @include media('<tablet') {
      padding: 0 10px;
    }
      
      &::before {
        position: absolute;
        left: 0;
        padding-left: 9999px;
        margin-left: -9999px; 
        content: '';
        background: $brown;
        height: 1px;
        top: 57%; 
      }

      &::after {
        position: absolute;
        right: 0;
        padding-right: 9999px;
        margin-right: -9999px;
        content: '';
        background: $brown;
        height: 1px;
        top: 57%; 
      }
    }
}
.searchholder{

  input[type="text"]{
    border: none;
    background: none;
    padding:0 28px;
    line-height: 38px;
    font-family: 'Oswald';
    font-size: 14px;
    width: calc(100% - 90px);
    color: $gray3;
  }

  input[type="submit"]{
    border: none;
    background: url(../img/search-icon.png) no-repeat 0 0;
    float: right;
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin: 10px 15px 0 0;
    &:hover{
      background-position: 0 -16px;
    }
  }
}
.custom-pagination {
  border-radius: 0px;
  display: table;
  margin:0 auto;

    li {

      a{
        @include font-size(20x, 22px);
        border-radius: 0px !important;
        color: $brown;
        font-family: 'tablet-gothic-compressed',sans-serif;
        font-weight: 200;
        border-color: #bfaa80 !important;
        padding:0;
        height: 50px;
        line-height: 48px !important;
        width: 50px;
        text-align:center;
      }

    }
}
.custom-pagination > .active > a, .custom-pagination > .active > a:hover, .custom-pagination > .active > a:focus, .custom-pagination > .active > span, .custom-pagination > .active > span:hover, .custom-pagination > .active > span:focus{    
  background: #bfaa80;
  color: #fff;
  border-color: #bfaa80;
}

.page-numbers {
  &.prev {
    background: url(../img/page-number-bg.png) no-repeat 0 0;
    font-size: 0;
    line-height: 0;
  }
  &.next {
    background: url(../img/page-number-bg.png) no-repeat 0 100%;
    font-size: 0;
    line-height: 0;
  }
}

.openpos {
  iframe {
    width: 100% !important;
  }
}




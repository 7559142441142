/** @define recipeholder */
.recipeholder {
  display: inline-block;
  margin-top: 70px;
  vertical-align: top;
  width: 100%;

  @include media('<tablet') {
    margin-top: 30px;
  }
 

  &__head {
    @include font-size(39px, 42px);
    color: $brown;
    font-family: 'tablet-gothic-compressed',sans-serif;
    font-weight: 200;
    letter-spacing: 2.5px;
    margin: 0 0 15px;
    position: relative;
    text-transform: uppercase;

    @include media('<tablet') {
      @include font-size(24px, 30px);
    }
  }
  
  &__ul {
    @include font-size(18px, 29px);
    color: $gray3;
    font-family: 'Oswald';
    font-weight: 400;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

/** @define recipe-right */
.recipe-right {
  @include font-size(14px, 29px);
  color: $gray3;
  display: block;
  font-family: 'proxima-nova',sans-serif;
  font-weight: 400;

  &__ol {
    margin: 0 0 0 14px;
    padding: 0;

    @include media('<tablet') {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}


/* stylelint-disable */

.recipes {
  margin-bottom: 110px;
}
.recipe-right {
  p { 
    margin-top: 0;
    @include media('<tablet') {
      margin-top: 30px;
    }
  }
  ol {
    color: $gray3;
    padding:0;
    width:100%;
    overflow:hidden;
    counter-reset:item;
  }
  ol li {padding:0 0 0 17px;}
  ol li:before,
  ol .num {
    float:left;
    width:17px;
    font-weight: 700;
    margin:0 0 0 -17px;
    color: $brown;
    content:counters(item, ".") ".";
    counter-increment:item;
  }
}

/** @define upcoming-events */
.upcoming-events {
  padding: 70px 0 0;
  position: relative;

  @include media('<desktop') {
    padding: 30px 0 0;
  }

  &__holder {
    font-family: 'proxima-nova',sans-serif;
    position: relative;

    &--heading {
      @include font-size(79px, 83px);
      color: $brown;
      font-family: 'tablet-gothic-compressed',sans-serif;
      font-weight: 200;
      letter-spacing: 4px;
      margin: 0;
      position: relative;
      text-align: left;

      @include media('<desktop') {
        @include font-size(40px, 43px);
      }

      @include media('<tablet') {
        text-align: center;
      }
    }
  }
}

/** @define upcomingevents-list  */
.upcomingevents-list {
  display: block;
  list-style-type: none;
  margin: 0;

  @include media('<tablet') {
    padding: 0;
  }

  &__date {
    border: 1px solid #bfaa80;
    color: $brown;
    font-family: 'tablet-gothic-compressed',sans-serif;
    font-size: 18px;
    font-weight: 200;
    letter-spacing: 2px;
    padding: 1px;
    text-transform: uppercase;

    @include media('<tablet') {
      margin: 0 auto 28px;
      width: 68px;
    }
  }

}

/** @define upcomingevents-list-desc  */
.upcomingevents-list-desc {
  margin: 6px 0 0;
  padding-left: 30px;

  @include media('<widescreen') {
    margin: 0;
  }

  @include media('<tablet') {
    padding-left: 15px;
  }

  &__heading {
    color: $gray3;
    font-family: 'Oswald';
    font-size: 18px;
    font-weight: 400;
    margin: 0;
  }

  &__text {
    color: $gray3;
    font-family: 'proxima-nova';
    font-size: 14px;
    line-height: 28px;
    margin: 5px 0 0;
  }
}

/* stylelint-disable */
.bg-imagetext {
  left: 0;
  margin: 0 auto;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: -50px;
  width: 100%;
}
.upcoming-events__holder--heading {
  span {
    display: inline-block;
    vertical-align: top;
    padding: 0 35px;
    position: relative;
    z-index: 9;

    @include media('<tablet') {
      padding: 0 10px;
    }
      
      &::before {
        position: absolute;
        left: 0;
        padding-left: 9999px;
        margin-left: -9999px; 
        content: '';
        background: $brown;
        height: 1px;
        top: 57%; 
      }

      &::after {
        position: absolute;
        right: 0;
        padding-right: 9999px;
        margin-right: -9999px;
        content: '';
        background: $brown;
        height: 1px;
        top: 57%; 
      }
    }
}
.upcomingevents-list__date span {
  font-size: 40px;
  display: block;
  line-height: 29px;
  margin:0 0 5px;
}
.txt-brown{
  color: $brown;
}
.upcomingevents-list-desc a{ 
  color: inherit; 
  text-decoration: none;

  &:hover {
    color: #000;
  }
}
.upcomingevents-list li {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin: 58px 0 0;
  @include media('<tablet') {
    text-align: center;
  }

  .btn-default {
    margin-top: 20px;
    @include media('<tablet') {
      display: inline-block;
      vertical-align: top;
      float: none;
    }
  }
  
}


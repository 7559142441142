/** @define schedule-area */
.schedule-area {
  padding: 88px 0;
  position: relative;

  @include media('<tablet') {
    padding: 20px 0 40px;
  }
}




/* stylelint-disable */
.schedule-area {
  @include media('<tablet') { 
    .sec-data__heading {
      @include font(35px, 40px);

    }
  }
}
.schedule-form {
  position: relative;
  max-width: 1146px;
  margin: 0 auto;

  @include media('<widescreen') {
    max-width: 900px;
  } 
  
  .hbspt-form {
    width: 231px;

    @include media('<tablet') {
      width: 96% !important;

    }
  }
  .form-columns-2 {
    width: 100% ;
    max-width: 100% !important;

    .field {
      width: 100% ;
    }
    .hs_venues_of_interest {
      position: absolute;
      right: 0;
      top: 0;
      width: 300px !important;
      .field {
        width: 300px !important;
      }
      @include media('<tablet') {
        position: static;
        width: 100% !important;
      }

    }
  }
}
.hbspt-form {
  label {
    @include font(15px, 18px);
    color: $gray3;

  }
  @include media('<tablet') {
    fieldset { max-width: 100% !important;}
  }
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  select {
    @include font(14px, 32px);
    width: 100% !important;
    border: 1px solid $gray3 !important;
    height: 32px !important;
    padding: 7px 10px !important;
    border-radius: 0 !important;
    background: none !important;
    max-width: 100% !important;
    box-sizing: border-box !important;
  }
  .hs_venues_of_interest {
    label {
      @include font(24px, 28px);
      color: $brown;
      margin: 0 0 15px !important;
      font-family: 'tablet-gothic-compressed',sans-serif;
      font-weight: 200 !important;
      display: block;
      text-transform: uppercase;
      letter-spacing: 1.8px;
    }
  }
  .inputs-list {
    li { margin-bottom: 10px;}
    label {
      @include font(14px, 18px);
      color: $gray3;
      font-family: "proxima-nova";
    }
  }
  label {
    @include font(14px, 18px);
    color: $gray3;
    font-family: "proxima-nova";
  }
  .hs-button {
    display: block !important;
    margin: 0 auto !important;
    width: 163px !important;
    height: 34px !important;
    line-height: 15px !important;
    padding: 0 !important;
    border-radius: 0 !important;
    font-size: 12px;
    font-family: "Oswald" !important;
    font-weight: 300 !important;
    text-shadow: none !important;
    color: $brown !important;
    box-sizing: border-box !important;

    @include media('<desktop') {
      margin: 0 !important;
    }
  }
  .hs_submit { 
    width: 1140px;
    @include media('<widescreen') {
      width: 900px;
    }
    @include media('<desktop') {
      width: 100%;
    }
    

  }
}
.hs-richtext {
  h4 {
    @include font(24px, 28px);
    color: $brown;
    margin: 0 0 15px;
    font-family: 'tablet-gothic-compressed',sans-serif;
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: 1.8px;
  }
}
.hbspt-form {
  ::-webkit-input-placeholder { 
    color: #c7c7c7;
  }
  :-moz-placeholder { 
    color: #c7c7c7;
  }
  ::-moz-placeholder { 
    color: #c7c7c7;
    opacity: 1;
  }
  :-ms-input-placeholder { 
    color: #c7c7c7;
  }
  .hs_estimated_event_date {
    position: absolute;
    left: 296px;
    top: 0;
    width: 230px !important;

    @include media('<desktop') {
      position: static;
    }
  }
  #label-estimated_event_date-35cb2337-dd57-4d2d-8a9a-10bbe39e1ee2 {
    @include font(24px, 28px);
    color: $brown;
    margin: 0 0 15px;
    font-family: 'tablet-gothic-compressed',sans-serif;
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: 1.8px;
  }
  .form-columns-3 {
    
    .hs-form-field {
      width: 100% !important;
      float: none !important;
    }
  }
  .hs_estimated_guest_count {
    position: absolute;
    left: 580px;
    top: 0;
    width: 230px !important;
    z-index: 999;
    .field {
      width: 230px !important;
    }
    @include media('<widescreen') {
      position: static;
    }
  }
  .hs_how_did_you_hear_about_us_ {
    position: absolute;
    left: 580px;
    top: 100px;
    width: 230px !important;
    .field {
      width: 230px !important;
    }
    @include media('<widescreen') {
      position: static;
    }
  }
  .hs_tell_us_about_your_dream_day {
    width: 550px !important;
    margin-left: 270px !important;
    margin-top: 260px;

    @include media('<widescreen') {
      width: 600px !important;
      margin-left: 150px !important;
    }

    @include media('<desktop') {
      margin-left: 0 !important;
      margin-top: 0;
    }

    @include media('<tablet') {
      width: 100% !important;
    }

    textarea {
      @include font(14px, 32px);
      width: 100% !important;
      border: 1px solid $gray3 !important;
      height: 80px !important;
      padding: 7px 10px !important;
      border-radius: 0 !important;
      background: none !important;
      max-width: inherit !important;
      box-sizing: border-box !important;
      resize: none;
    }
    label {
      width: 100% !important;
      text-align: center !important;
      @include font(24px, 28px);
      color: $brown;
      margin: 0 0 15px !important;
      font-family: 'tablet-gothic-compressed',sans-serif;
      font-weight: 200 !important;
      text-transform: uppercase;
      letter-spacing: 1.8px;
      @include media('<tablet') {
        font-size: 14px;
      }
    }
  }
}
.page-id-2568 {
  .visual-internal__image img { width: 100%;}
}
.hs_firstname ,
.hs_lastname,
.hs_email,
.hs_phone,
.hs_preferred_tour_dates { 
  width: 100% !important;
}
.page-template-contact-details .hs_tell_us_about_your_dream_day{
  margin-top: 0 !important
}
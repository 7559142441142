/** @define bridesmaids-area */
.bridesmaids-area {
  padding: 70px 0 0;
  
  @include media('<tablet') {
    padding-top: 30px;
  }

  &__fullblock {
    margin-top: 65px;

    @include media('<tablet') {
      margin-top: 20px;
    }
  }
}

/** @define two-columns */
.two-columns {
  padding: 82px 0 131px;

  @include media('<widescreen') {
    padding: 50px 0 60px;
  }

  @include media('<tablet') {
    padding: 40px 0 20px;
  }
}

/** @define celeb-sec-image */
.celeb-sec-image {
  background: url('../img/bridemaid-img2.jpg') no-repeat;
  background-size: cover;
  padding: 81px 0 101px;

  @include media('<extrawidescreen') {
    padding: 30px 0 40px; 
  }

  @include media('<widescreen') {
    padding: 30px 0 40px; 
  }

  @include media('<desktop') {
    background: none;
    padding: 0 0 40px; 
  }

  &__image3 {
    display: none;

    @include media('<desktop') {
      display: block;
      margin-bottom: 20px;
    }
  }
}

/** @define gallery-sec */
.gallery-sec {
  padding: 15px 0 0;
  position: relative;

  &__image {
    bottom: -12px;
    left: -170px;
    margin: 0 0 10px;
    position: absolute;
    z-index: 999;

    @include media('<widescreen') {
      left: 0;
      max-width: 54%;
    }

    @include media('<desktop') {
      left: 0;
      margin-bottom: 25px;
      max-width: 100%;
      position: relative;
      right: 0;
      width: 100%;
    }

  }
}


/** @define logistics-sec */
.logistics-sec {
  background: url('../img/bridemaid-img4.jpg') no-repeat;
  background-size: cover;
  margin-bottom: 82px;
  padding: 86px 0 93px;
  position: relative;
  z-index: 99;

  @include media('<widescreen') {
    padding: 40px 0 30px;
  }

  @include media('<desktop') {
    margin-bottom: 30px;
    padding: 0 0 30px;
  }

  &__image2 {
    display: none;

    @include media('<desktop') {
      display: block;
      margin: 0 -21px 30px;
      position: relative;
      width: 112%;
      
    }
  }
}


/* stylelint-disable */
.two-columns {
  .sec-data {
    padding: 0 4%;
  }
  .btn-default {
    margin: 0 auto;
    display: block;
    float: none;
  }
  .sec-data__heading {
    margin-bottom: 34px;
  }
}
.bridesmaids {
  .venues {
    &:after { display: none;}
    @include media('<widescreen') {
      padding-bottom: 20px;
      padding-top: 10px;
    }
  }
}
.bridesmaids-area__fullblock {
  img { width: 100%;}
}
.logistics-sec {
  .sec-data {
    color: $white;
  }
  .logistics-sec__image2 {
    img { width: 100%;}
  }
}
.gallery-sec {
  .container { 
    position: relative;
    padding-bottom: 128px;
    @include media('<widescreen') {
      padding-bottom: 50px;
    }
  }

}

@include media('<tablet') {
  .two-columns .sec-data {
    margin-bottom: 15px;
  }
  .two-columns .sec-data__heading { margin-bottom: 15px;}
  .two-columns .sec-data { padding: 0;}
}


/** @define header */
.header {
  background: $white;
  left: 0;
  padding: 24px 75px 22px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
  
  @include media('<widescreen') {
    padding: 24px 15px 22px;
  }

  @include media('<desktop') {
    padding: 24px 15px 22px;
    position: relative;
  }

  &__logo {
    background: url('../img/logo.png') no-repeat;
    display: block;
    float: left;
    font-size: 0;
    height: 28px;
    line-height: 0;
    transition: all 0.4s ease;
    width: 132px;

    &--link {
      display: block;
      height: 100%;
    }
    
  }
}

/** @define gallery-area */
.gallery-area {
  padding: 88px 0 120px;
  position: relative;

  @include media('<tablet') {
    padding-bottom: 30px;
    padding-top: 20px;

  }
}


/** @define gallery-row */
.gallery-row {
  position: relative;

  &__title {
    position: relative;
  } 
}

/** @define gallery-content */
.gallery-content {
  overflow: hidden;
  padding-bottom: 100px;
  position: relative;
  

  &__image {
    border: 1px solid $brown;
    margin-bottom: 26px;
    padding: 19px;
    position: relative;

    &--img {
      height: 320px;
      object-fit: cover;
      width: 100%;

      @include media('<desktop') {
        height: 220px;
      }
    }
  }
}



/* stylelint-disable */
.gallery-row {
  .open-menu2 {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    box-sizing: border-box;
    color: $brown;
    @include font-size(40px, 45px);
    padding: 28px 31px 33px;
    text-transform: uppercase;
    cursor: pointer;
    height: auto;
    
    @include media('<tablet') {
      @include font-size(20px, 25px);
      padding: 15px 31px;
    }

    i {
      right: 36px;
      &:before {
        width: 29px;
      }

      &:after {
        width: 29px;
      } 
    }
  }
  .btn-holder {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    padding: 16px 0 50px;
    clear: both;

    @include media('<tablet') {
      padding: 10px 0 30px;
    }
    .btn-default {
      float: none;
      display: block;
      margin: 0 auto; 
    }
  }
  &:last-child {
    border-bottom: 1px solid $brown;
  }
}
.gallery-holder {
  padding:20px 0 80px;

  @include media('<tablet') {
    padding-left: 15px;
    padding-right: 15px;
  }
  .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .modal-content {
    overflow: hidden;
    box-shadow: none !important;
    border: none !important;
    background: none !important;
  }
  .modal-dialog {
    width: auto;
    max-width: 1200px;
  }
  .carousel-inner {
    img {
      width: auto !important;
      height: auto;
      display: block;
      max-width: 100% !important;
      max-height: 100vh;
    }
  }
  .modal {
      background: #484D53 !important;
    }
  .close-modal {
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    z-index: 9999;
    width: 400px;
    height: 100%;
    margin: 0 0 0 -200px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: url('/wp-content/themes/cescaphe/public/icon-pause.cur'), auto;
    z-index: 99999;

    span {
      display: none;
      color: $brown;
      font-size: 40px;
      display: none;
    }
  }
  .carousel-control {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.left {
      cursor: url('/wp-content/themes/cescaphe/public/prev_icon.cur'), auto;
      z-index: 9999;
      background: none !important;
    }

    &.right {
      cursor: url('/wp-content/themes/cescaphe/public/next_icon.cur'), pointer !important;
      z-index: 9999;
      background: none !important;
    }

    .icon-chevron-right {
      cursor: url('/wp-content/themes/cescaphe/public/prev_icon.cur') auto;
      z-index: 9999;
      background: none !important;
    }

    &:hover {
      span {
        display: block;
      }
    }
  }
}
@include media('<tablet') {
.gallery-content .col-sm-4 {
    display: table !important;
  }
}



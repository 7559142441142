
/** @define our-venues */
.our-venues {
  padding: 70px 0 0;
  position: relative;

  @include media('<desktop') {
    padding: 30px 0 0;
  }

  &__holder {
    font-family: 'proxima-nova',sans-serif;
    position: relative;

    &--heading {
      @include font-size(79px, 83px);
      color: $brown;
      font-family: 'tablet-gothic-compressed',sans-serif;
      font-weight: 200;
      letter-spacing: 4px;
      margin: 0;
      position: relative;
      text-align: left;

      @include media('<desktop') {
        @include font-size(40px, 43px);
      }

      @include media('<tablet') {
        @include font-size(30px, 34px);
        text-align: center;
      }
    }
  }
}

/** @define map-image */
.map-image {
  margin: 90px 0 0;
  position: relative;

  &__image {
    display: block;
    height: auto;
    width: 100%;
  }
}

/** @define our-venues-list  */
.our-venues-list {
  display: block;
  list-style-type: none;
  margin: 0;
  overflow: hidden;

  @include media('<tablet') {
    padding: 0;
  }

  &__item {
    display: inline-block;
    margin: 40px 0 0 60px;
    width: calc(50% - 60px);

    @include media('<tablet') {
      margin-left: 0;
      margin-top: 5px;
      width: 100%;
    }

    &--head {
      @include font-size(40px, 42px);
      color: $brown;
      font-family: 'tablet-gothic-compressed',sans-serif;
      font-weight: 200;
    }

    &--text {
      @include font-size(18px, 22px);
      color: $gray3;
      margin-top: 6px;
    }
  }


}

/* stylelint-disable */
.our-venues__holder--heading {
  span {
    display: inline-block;
    vertical-align: top;
    padding: 0 35px;
    position: relative;
    z-index: 9;

    @include media('<tablet') {
      padding: 0 10px;
    }
      
      &::before {
        position: absolute;
        left: 0;
        padding-left: 9999px;
        margin-left: -9999px; 
        content: '';
        background: $brown;
        height: 1px;
        top: 57%; 
      }

      &::after {
        position: absolute;
        right: 0;
        padding-right: 9999px;
        margin-right: -9999px;
        content: '';
        background: $brown;
        height: 1px;
        top: 57%; 
      }
    }
}
.our-venues-list__item:nth-child(odd) {
  margin-left: 0;
}
.our-venues-list__item--head a{ 
  color: inherit; 
  text-decoration: none;

  &:hover {
    color: #000;
  }
}

